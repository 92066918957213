import { Injectable } from '@angular/core'
import { HttpInterceptor, HttpRequest, HttpHandler } from '@angular/common/http'

@Injectable()
export class ApiInterceptorsService implements HttpInterceptor {

    constructor() {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        const langCurrent = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en'
        const apiToken = localStorage.getItem('token')
        if (apiToken) {
            const clonedReq = req.clone({
                setHeaders: {
                    Authorization: `Bearer ${apiToken}`,
                    lang: `${langCurrent}` // current language
                }
            })

            return next.handle(clonedReq)
        }

        const headers = {
            lang: `${langCurrent}` // current language
        }
        const reqClone = req.clone({
            setHeaders: headers
        })
        return next.handle(reqClone)
    }
}
