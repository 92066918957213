import { AbstractControl, FormGroup, NgForm } from '@angular/forms'

export class UIHelpers {

    checkErrors (fc: any, form: any): boolean {
        return fc.invalid && (fc.touched || fc.dirty || form.submitted)
    }

    showAllErrors (fg: FormGroup): void {
        const formControls: { [key: string]: AbstractControl<any> } = fg.controls

        Object.keys(formControls).forEach(controlName => {
            const control = formControls[controlName]
            control.markAsDirty()
            control.markAsTouched()
        })
    }
}
