import { Component, Input, OnInit } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { UIHelpers } from '../../helpers/ui-helpers'
import { ApiService } from '../../services/api.service'

@Component({
    selector: 'app-rg-errors',
    templateUrl: './rg-errors.component.html',
    styleUrls: ['./rg-errors.component.css']
})
export class RgErrorsComponent implements OnInit {
    @Input() control: any

    @Input() f: any

    @Input() label: string

    @Input() taId: string

    lang: any

    constructor (private ts: TranslateService, private api: ApiService, public ui: UIHelpers) {
        this.lang = this.api.translate('common.errors')
        this.lang.subscribe((d: any) => {
            this.lang = d
        })
    }

    ngOnInit () {
        
    }
}
