import { Component, Input, OnInit } from '@angular/core'

@Component({
    // eslint-disable-next-line
    selector: 'rg-badge',
    templateUrl: './rg-badge.component.html',
    styleUrls: ['./rg-badge.component.scss']
})
export class RgBadgeComponent implements OnInit {
    constructor () { }

    ngOnInit () {
    }

}
