declare interface Array<T> {
    findById(id: number): T | false
    findByKey(key: string, value: any): T | false

    delById(id: number): boolean
    delByKey(key: string, value: any): boolean
}


/**
 * Find
 */
Array.prototype.findByKey = function (key: string, value: any) {
    // eslint-disable-next-line
    const _this = this
    const resp: any = this.find( (obj: any) => {
        return obj[key] == value
    })

    return resp ? resp as typeof _this : false
}

Array.prototype.findById = function (id: number) {
    return this.findByKey('id', id)
}

/**
 * Delete
 */
Array.prototype.delByKey = function (key: string, value: any) {
    const index: number = this.findIndex( (obj: any) => {
        return obj[key] == value
    })

    if (index >= 0) {
        this.splice(index, 1)
        return true
    }

    return false
}

Array.prototype.delById = function (id: number) {
    return this.delByKey('id', id)
}
