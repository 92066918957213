import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

@Component({
    // eslint-disable-next-line
    selector: 'rg-button',
    templateUrl: './rg-button.component.html',
    styleUrls: ['./rg-button.component.scss']
})
export class RgButtonComponent implements OnInit {
    @Input() class: string = ''

    @Input() styles: string = ''

    @Input() type: 'button' | 'submit' = 'button'

    @Input() disabled: boolean = false

    @Input() loader: boolean = false

    @Output() btnClicked: EventEmitter<any> = new EventEmitter()

    constructor () { }

    ngOnInit () {
    }

    buttonClicked ($event: any) {
        this.btnClicked.emit($event)
    }
}
