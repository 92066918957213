<div>
    <div class="d-flex flex-row-reverse">
        <div class="button-container">
            <button type="button" class="rg primary me-1 button-label" (click)="toggleFilterView()"><i class="fa fa-filter" aria-hidden="true"></i></button>
            <div id="popupDiv" class="popup" [style.display]="filterShow ? 'block' : 'none'">This is to show a filter</div>
        </div>
        <button type="button" class="rg primary me-1" (click)="export()">Print</button>
        <button type="button" class="rg primary me-1" (click)="exportToCSV()">Export to CSV</button>
        <button type="button" class="rg primary me-1" (click)="exportToExcel()">Export to Excel</button>
        <button type="button" class="rg primary me-1" (click)="exportToPDF()">Export to PDF</button>
    </div>
    <apx-chart
        #chart
        [chart]="chartOptions.chart ? chartOptions.chart : { type: rgChartOption.chartType }"
        [annotations]="chartOptions.annotations ? chartOptions.annotations : {}"
        [colors]="chartOptions.colors ? chartOptions.colors : []"
        [dataLabels]="chartOptions.dataLabels ? chartOptions.dataLabels : {}"
        [series]="chartOptions.series ? chartOptions.series : []"
        [stroke]="chartOptions.stroke ? chartOptions.stroke : {}"
        [labels]="chartOptions.labels ? chartOptions.labels : []"
        [legend]="chartOptions.legend ? chartOptions.legend : {}"
        [markers]="chartOptions.markers ? chartOptions.markers : {}"
        [noData]="chartOptions.noData ? chartOptions.noData : {}"
        [fill]="chartOptions.fill ? chartOptions.fill : {}"
        [tooltip]="chartOptions.tooltip ? chartOptions.tooltip : {}"
        [plotOptions]="chartOptions.plotOptions ? chartOptions.plotOptions : {}"
        [responsive]="chartOptions.responsive ? chartOptions.responsive : []"
        [xaxis]="chartOptions.xaxis ? chartOptions.xaxis : {}"
        [yaxis]="chartOptions.yaxis ? chartOptions.yaxis : {}"
        [forecastDataPoints]="chartOptions.forecastDataPoints ? chartOptions.forecastDataPoints : {}"
        [grid]="chartOptions.grid ? chartOptions.grid : {}"
        [states]="chartOptions.states ? chartOptions.states : {}"
        [title]="chartOptions.title ? chartOptions.title : {}"
        [subtitle]="chartOptions.subtitle ? chartOptions.subtitle : {}"
        [theme]="chartOptions.theme ? chartOptions.theme : {}"
        [autoUpdateSeries]="chartOptions.autoUpdateSeries ? chartOptions.autoUpdateSeries : false"
    >
    </apx-chart>
</div>
