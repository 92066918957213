import { Directive } from '@angular/core'

@Directive({
    selector: '[appRgTooltip2]'
})
export class RgTooltip2Directive {

    constructor () { }

}
