import { PipeTransform, Pipe } from '@angular/core'
import * as moment from 'moment'

@Pipe({ name: 'df' })
export class FormatDatePipe implements PipeTransform {
    constructor () {
    }

    transform (date: string | Date, format: string): any {
        return moment(date).format(format)
    }
}
