import { Component, Input, OnInit } from '@angular/core'
import { ApiService } from 'src/app/services/api.service'

@Component({
    selector: 'app-no-data-found',
    templateUrl: './no-data.component.html',
    styleUrls: ['./no-data.component.css']
})
export class NoDataComponent implements OnInit {
    lang: any

    constructor (
        private api: ApiService
    ) {
        this.lang = this.api.translate('common')
        this.lang.subscribe((d: any) => {
            this.lang = d
        })
    }

    ngOnInit () {
    }

}
