import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core'
import { Customer } from '../models/customer'
import { ApiService } from '../services/api.service'

@Directive({
    // eslint-disable-next-line
    selector: '[customerConfig]'
})
export class CustomerConfigDirective implements OnInit {
    private elseTemplate: TemplateRef<HTMLTemplateElement>
    
    @Input() customerConfig: string
    
    @Input() set customerConfigElse (elseTem: TemplateRef<HTMLTemplateElement>) {
        this.elseTemplate = elseTem
    }

    constructor (
        private templateRef: TemplateRef<any>,
        private vcr: ViewContainerRef,
        private api: ApiService
    ) {
        
    }

    ngOnInit(): void {
        const result = this.getConfigStatus()
        
        this.renderTemplate(result)
    }

    getConfigStatus () {
        let results: boolean = true
        if (this.api.user.userable_type != 'customer') {
            results = false
        }

        const customer: Customer = this.api.user.userable as Customer
        const result: boolean | any = customer[this.customerConfig as keyof Customer]
        results = typeof result == 'boolean' ? result : false
        
        return result
    }

    private renderTemplate (result: boolean) {
        this.vcr.clear()

        if (result) {
            this.vcr.createEmbeddedView(this.templateRef)
        }
        else if (this.elseTemplate) {
            this.vcr.createEmbeddedView(this.elseTemplate)
        }
    }
}
