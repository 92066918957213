import { PipeTransform, Pipe } from '@angular/core'
import { ConstantsService } from '../services/constants.service'

@Pipe({ name: 'constants' })
export class ConstantsPipe implements PipeTransform {
    constructor (private cs: ConstantsService) { }

    transform (value: string): any {
        return this.cs[value as keyof ConstantsService]
    }
}
