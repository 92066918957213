import { Component, ElementRef, Input, OnInit, Renderer2 } from '@angular/core'

@Component({
    selector: 'app-rg-spinner',
    templateUrl: './rg-spinner.component.html',
    styleUrls: ['./rg-spinner.component.scss']
})
export class RgSpinnerComponent implements OnInit {
    @Input() color = '#000'

    @Input() size = '100px'

    @Input() border = '6px'

    @Input() speed = '1.2s'

    @Input() class = ''

    constructor (private elementRef: ElementRef, private r2: Renderer2) {
    }

    ngOnInit () {
        this.elementRef.nativeElement.style.setProperty('--color', this.color)
        this.elementRef.nativeElement.style.setProperty('--size', this.size)
        this.elementRef.nativeElement.style.setProperty('--border', this.border)
        this.elementRef.nativeElement.style.setProperty('--speed', this.speed)
    }

}
