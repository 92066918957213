import { AfterViewInit, Component, ComponentRef, ElementRef, Input, OnInit, Renderer2, TemplateRef, ViewChild } from '@angular/core'
import { NgForm } from '@angular/forms'
import identifiers from 'src/app/services/component-identifiers'
import { DialogConfigs, DialogService, DialogType } from './dialog.service'

@Component({
    selector: 'app-rg-dialog',
    templateUrl: './rg-dialog.component.html',
    styleUrls: ['./rg-dialog.component.scss']
})
export class RgDialogComponent implements OnInit, AfterViewInit {
    @Input() dynamicTemplate: TemplateRef<any> | null = null

    @Input() public componentRef: ComponentRef<any>

    @Input() public configs: DialogConfigs

    @Input() styles: any = {}

    @ViewChild('actions') actions: TemplateRef<any>

    @ViewChild('form') form: NgForm

    constructor (public ds: DialogService, private elementRef: ElementRef, private renderer: Renderer2) {
    }

    ngOnInit () {
        this.setDefaultStyle()
    }

    ngAfterViewInit (): void {

    }

    addClasses (classes: string) {
        this.renderer.addClass(this.componentRef.location.nativeElement, classes)
    }

    setDefaultStyle () {
        const styles: object = {
            titleColor: '#555',
            statementColor: '#555',

            btnConfirmBg: '#EB1D36',
            btnConfirmFg: '#fff',

            btnDeclineBg: '#31b341',
            btnDeclineFg: '#fff',

            btnDoneBg: '#002E94',
            btnDoneFg: '#fff',

            btnCancelBg: '#101828',
            btnCancelFg: '#fff'
        }

        Object.entries(styles).map(([key, value]) => {
            const val = this.styles.hasOwnProperty(key) ? this.styles[key] : value
            this.elementRef.nativeElement.style.setProperty(`--${key}`, val)
        })
    }

    close () {

        this.ds.closeDialog(this.componentRef)
    }

    get DialogType () {
        return DialogType
    }

    get identifiers () {
        return identifiers.dialog
    }
}
