<div class="alert-wrapper animate__animated" [class.hide]="hide" [class.show]="!hide">
    <div class="icon">
        <div class="icon-box">
            <i class="fas fa-check-circle" *ngIf="type==='success'"></i>
            <i class="fas fa-exclamation-triangle" *ngIf="type==='warning' || type==='danger'"></i>
            <i class="fas fa-info-circle" *ngIf="type==='info'"></i>
        </div>
    </div>

    <div class="content">
        <ng-content></ng-content>
        <button type="button" class="rg primary sm ms-2" *ngIf="buttonYes" (click)="yesClicked($event)">Yes</button>
        <button type="button" class="rg secondary sm ms-2" *ngIf="buttonNo" (click)="noClicked($event)">No</button>
    </div>

    <div class="close" (click)="hide=true" *ngIf="!persistent">
        <i class="fas fa-times"></i>
    </div>
</div>
