import { Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core'
import { Subject, Subscription, debounceTime, distinctUntilChanged } from 'rxjs'
import identifiers from 'src/app/services/component-identifiers'
import { ApiService } from '../../services/api.service'

@Component({
    selector: 'app-rg-auto-complete',
    templateUrl: './rg-auto-complete.component.html',
    styleUrls: ['./rg-auto-complete.component.scss']
})
export class RgAutoCompleteComponent implements OnInit, OnDestroy, OnChanges {
    @Input() taId: string = identifiers.autoComplete.identifier

    @Input() optionTemplate: TemplateRef<HTMLElement> | null = null

    @Input() itemTemplate: TemplateRef<HTMLElement> | null = null

    @Input() emptyTemplate: TemplateRef<HTMLElement> | null = null

    @Input() data: Array<Option> = []

    @Input() disabled: boolean = false

    @Input() styles: any = { }

    @Input() configs: Configs = { } as Configs

    @Input() initials: Array<number> = []

    @Output() itemSelected: EventEmitter<Option> = new EventEmitter()

    @Output() itemRemoved: EventEmitter<Option> = new EventEmitter()

    @Output() itemRemovalRequested: EventEmitter<Option> = new EventEmitter()

    @Output() itemClicked: EventEmitter<Option> = new EventEmitter()

    @Output() keywordChanged = new EventEmitter()

    @ViewChild('optionTemplate', { static: false }) defaultOptionTemplate: TemplateRef<HTMLElement>

    @ViewChild('selectedItemTemplate', { static: false }) defaultSelectedItemTemplate: TemplateRef<HTMLElement>

    @ViewChild('emptyTemplate', { static: false }) defaultEmptyTemplate: TemplateRef<HTMLElement>

    @ViewChild('body', { static: false }) refBody: ElementRef

    @ViewChild('searchInput', { static: false }) refSearchInput: ElementRef

    searchKeyword: string

    searchKeyword$: Subject<string> = new Subject<string>()

    searchKeywordSub: Subscription

    availableItems: Array<Option> = []

    @Input() selectedItems: Array<Option> = []

    uiBodyStatus: boolean = false

    constructor (public api: ApiService, private elementRef: ElementRef) {

        this.searchKeywordSub = this.searchKeyword$.pipe(
            debounceTime(1000), // wait 1 sec after the last event before emitting last event
            distinctUntilChanged() // only emit if value is different from previous value
        ).subscribe((searchKeyword: string) => {
            // api calls if need to fetch data from backend server
            this.keywordChanged.emit(searchKeyword)
        })
    }

    ngOnInit () {
        if (this.optionTemplate === null) {
            this.optionTemplate = this.defaultOptionTemplate
        }

        if (this.itemTemplate === null) {
            this.itemTemplate = this.defaultSelectedItemTemplate
        }

        if (this.emptyTemplate === null) {
            this.emptyTemplate = this.defaultEmptyTemplate
        }

        this.setDefaultOptions()
        this.syncAvailableItems()
        this.syncInitialItems()
        this.setDefaultStyle()
        this.setDefaultConfigs()
    }

    ngOnChanges (changes: SimpleChanges): void {
        if (changes.data) {
            this.data = changes.data.currentValue
            this.setDefaultOptions()
            this.syncAvailableItems()
            this.syncInitialItems()
            this.setDefaultStyle()
            this.setDefaultConfigs()
        }
    }

    ngOnDestroy (): void {
        this.searchKeywordSub.unsubscribe()
    }

    syncInitialItems () {
        this.initials.map( (key: number) => {
            const initial = this.availableItems.find( (item: Option) => item.key == key)
            if (initial) {
                this.itemAction(initial, this.availableItems, this.selectedItems)
            }
        })
    }

    setDefaultOptions () {
        const options: object = {
            active: true,
            removable: true,
            loading: false,
            disabled: false
        }

        this.data.forEach( (op: Option, i: number) => {
            this.data[i] = { ...options, ...op }
        })
    }

    setDefaultStyle () {
        const styles: object = {
            borderColor: 'var(--ap-container-border-shadow)',
            selectedItemsBackground: 'var(--ap-container-border-shadow)',
            selectedItemsColor: 'var(--ap-input-fg)',

            listItemsBackground: 'var(--ap-primary)',
            listItemsColor: 'var(--ap-btn-fg)',

            activeItemBackground: 'var(--ap-primary)',
            activeItemColor: 'var(--ap-btn-fg)'
        }

        Object.entries(styles).map( ([key, value]) => {
            const val = this.styles.hasOwnProperty(key) ? this.styles[key] : value
            this.elementRef.nativeElement.style.setProperty(`--${key}`, val)
        })
    }

    setDefaultConfigs () {
        const configs: Configs = {
            removalRequest: false,
            singleSelect: false
        }

        Object.entries(configs).map( ([key, value]) => {
            this.configs[key as keyof Configs] = this.configs.hasOwnProperty(key) ? this.configs[key as keyof Configs] : value
        })
    }

    searchKeywordChange (value: string) {
        this.searchKeyword$.next(value)
    }

    syncAvailableItems () {
        this.availableItems = this.api.deepClone(this.data)
        this.selectedItems.map( (sItem: Option) => {
            const i = this.availableItems.findIndex( (aItem: Option) => aItem.key == sItem.key)
            if (i > -1) {
                this.availableItems.splice(i, 1)
            }
        })
    }

    /**
     * This method will trigger when an new item will be selected
     * from the available list of items.
     * @param item Option
     */
    selectItem (item: Option) {
        if (item?.disabled) {
            return
        }

        if (this.configs.singleSelect == true && this.selectedItems.length > 0) {
            this.itemAction(this.selectedItems[0], this.selectedItems, this.availableItems)
        }

        this.searchKeyword = ''

        item.active = true
        item = this.itemAction(item, this.availableItems, this.selectedItems)
        this.uiBodyStatus = false
        this.itemSelected.emit(item)
    }

    selectItemById (id: number) {
        const item = this.availableItems.find((it: Option) => it.key == id)
        if (item) {
            this.selectItem(item)
            this.itemClicked.emit(item)
        }
    }

    removeItemById (id: number) {
        const item = this.selectedItems.find((it: Option) => it.key == id)

        if (item) {
            this.itemAction(item, this.selectedItems, this.availableItems)
        }
    }

    removeItem (item: Option, event: any) {
        event.preventDefault()
        event.stopPropagation()
        item.active = false

        if (this.configs.removalRequest == true) {
            item.proceedRemoval = () => {
                this.itemAction(item, this.selectedItems, this.availableItems)
            }
            this.itemRemovalRequested.emit(item)
        }
        else {
            this.itemAction(item, this.selectedItems, this.availableItems)
            this.itemRemoved.emit(item)
        }
    }

    /**
     * This method will trigger when an already selected item will be clicked
     * by user.
     * @param item Option
     */
    clickItem (item: Option) {
        this.activateItem(item)
        this.itemClicked.emit(item)
    }

    activateItem (item: Option) {
        this.selectedItems.map( (op: Option) => {
            op.active = false
        })
        if (item?.disabled === false) {
            item.active = true
        }
    }

    itemAction (item: Option, from: Array<Option>, to: Array<Option>) {
        const clonedItem = this.api.deepClone(item)
        to.push(clonedItem)
        const index = from.findIndex( (f: Option) => f.key == item.key)
        from.splice(index, 1)

        return clonedItem
    }

    searchInpuClicked () {
        if (this.disabled === false) {
            this.uiBodyStatus = true
        }
    }

    @HostListener('document:click', ['$event'])
    clickEvent (event: any) {
        const element: HTMLElement = event.target as HTMLElement
        const classes = (event.target as Element).className.split(' ')

        if (
            this.uiBodyStatus == true &&
            !(this.refBody.nativeElement as HTMLElement).contains(element) &&
            !(this.refSearchInput.nativeElement as HTMLElement).contains(element)
        ) {
            this.uiBodyStatus = false
            event.preventDefault()
            event.stopPropagation()
        }
    }

    get identifiers () {
        return identifiers.autoComplete
    }
}

export interface Option {
    key: string | number
    value: string
    active?: boolean
    removable?: boolean
    loading?: boolean
    disabled?: boolean
    proceedRemoval?: () => void
    tooltip?: {
        title?: string
        statement: string
        type: 'success' | 'warning' | 'info' | 'danger'
    }
    [key: string]: any
}

export interface Configs {
    removalRequest?: boolean
    singleSelect?: boolean
}
