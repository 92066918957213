import { apis } from 'src/environments/environment'

import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { Observable } from 'rxjs'


@Injectable()
export class DataService {
    private baseUrl = `${apis.baseUrl}/public`

    constructor(public http: HttpClient, private router: Router) {
    }


    svgToPDF(formData: FormData): Observable<any> {
        const url = `${this.baseUrl}/svg-to-pdf`

        return this.http.post(url,formData, { responseType: 'blob'})
    }


}
