<div class="rg-table">
    <div class="d-flex align-items-end">
        <div class="rg-input-group me-1 w-100">
            <label>Search</label>
            <div class="group" style="position: relative">
                <div class="pre">
                    <button class="rg secondary">
                        <i class="fa fa-search"></i>
                    </button>
                </div>
                <input type="text" class="rg" [(ngModel)]="searchKeyword" (ngModelChange)="searchKeywordChange($event)" placeholder="Search" [attr.data-ta]="identifiers.searchInput" />
                <!--
                <div class="col-filters-list" *ngIf="colFiltersList.length > 0">
                    <ul>
                        <li *ngFor="let col of colFiltersList">{{ col.label }}</li>
                    </ul>
                </div>
                -->
            </div>
        </div>

        <div class="rg-input-group me-1" *ngIf="this.configs.pagination === true">
            <label>Per Page</label>
            <select class="rg per-page" [(ngModel)]="this.filters.per_page" [attr.data-ta]="identifiers.perPage">
                <option [value]="p" *ngFor="let p of 'PER_PAGE' | constants">{{ p }}</option>
            </select>
        </div>

        <div class="rg-input-group">
            <button [disabled]="configs.waiting.search" [class.in-progress]="configs.waiting.search" type="button" class="rg secondary" (click)="filters.page = 1; this.filtersChanged.emit(this.filters)" [attr.data-ta]="identifiers.searchButton">
                Search
                <span></span>
                <span></span>
                <span></span>
            </button>
        </div>

        <div class="rg-input-group ms-1">
            <div class="rg-button-group" #columnsList>
                <button class="rg pre info" style="width: 130px" (click)="columnList = true">Select Columns</button>
                <div class="icon post info" (click)="columnList = true">
                    <i class="fas fa-chevron-down"></i>
                </div>
                <div class="columns-list" [style.display]="columnList ? 'block' : 'none'">
                    <h5 class="primary">Please Select Columns</h5>
                    <ul>
                        <li class="empty-slot" [appDragDrop]="100" (orderChanged)="columnsOrderChanged($event)"></li>
                        <ng-container *ngFor="let col of configs.columns; let i = index">
                            <li class="item" [class.active]="!col.hide" (click)="col.hide = !col.hide" [appDragDrop]="i">
                                {{ col.label }}
                            </li>
                            <li class="empty-slot" [appDragDrop]="101 + i" (orderChanged)="columnsOrderChanged($event)"></li>
                        </ng-container>
                    </ul>

                    <div class="d-flex justify-content-center">
                        <button class="rg secondary" (click)="columnList = false">Done</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="table-responsive" [style.height]="configs.styles.tableHeight">
        <table class="rg primary alternate-rows">
            <thead class="sticky">
                <tr>
                    <ng-container *ngFor="let col of configs.columns">
                        <th [class.center]="col.align" *ngIf="!col?.hide || col?.hide == false" [style.width]="col.width">
                            <span>{{ col.label }}</span>
                            <span class="arrows" *ngIf="checkType(col.value) === 'string'">
                                <i (click)="doSort(col.value, 'ASC')" class="fas fa-long-arrow-alt-up" [class.active]="isActiveOrder(col.value, 'ASC')"></i>
                                <i (click)="doSort(col.value, 'DESC')" class="fas fa-long-arrow-alt-down" [class.active]="isActiveOrder(col.value, 'DESC')"></i>
                            </span>
                        </th>
                    </ng-container>
                </tr>
            </thead>
            <tbody *ngIf="(configs.waiting.data === 'done' || searchKeyword.length > 0) && data && data.length > 0">
                <tr *ngFor="let d of data | filter: searchKeyword; let i = index" [style]="configs.rows?.styles ? (d | callback: configs.rows?.styles) : ''" [attr.data-ta]="getIdentifier(d, configs.rows?.taIdentifier)">
                    <ng-container *ngFor="let col of configs.columns">
                        <td *ngIf="!col?.hide || col?.hide == false" [class.center]="col.align" [attr.data-ta]="getIdentifier(d, col?.taIdentifier)">
                            <div [style.width]="col.width" [routerLink]="col.link" [queryParams]="col.params ? (d | callback: col.params) : ''" [class.link]="col.link">
                                <ng-container *ngIf="checkType(col.value) === 'string'">
                                    <container-element [ngSwitch]="col?.uiType">
                                        <!-- Toggle -->
                                        <ng-container *ngSwitchCase="'Toggle'">
                                            <label class="rg-switch primary sm">
                                                <input type="checkbox" [checked]="col?.pipe ? (col.value | dynamicKeysReader: d | callback: col.pipe) : (col.value | dynamicKeysReader: d)" disabled="true" />
                                                <span class="slider round"></span>
                                            </label>
                                        </ng-container>

                                        <!-- MultiBadge -->
                                        <ng-container *ngSwitchCase="'MultiBadge'">
                                            <div class="multi-select-container">
                                                <rg-badge *ngFor="let item of col.value | dynamicKeysReader: d; let i = index">
                                                    <ng-container *ngIf="!col?.pipe">
                                                        {{ item[col.multiBadgeKey ? col.multiBadgeKey : 'name'] }}
                                                    </ng-container>

                                                    <ng-container *ngIf="col?.pipe">
                                                        {{ item | callback: col.pipe }}
                                                    </ng-container>
                                                </rg-badge>
                                            </div>
                                        </ng-container>

                                        <!-- CommaSeparated -->
                                        <ng-container *ngSwitchCase="'CommaSeparated'">
                                            <ng-container *ngFor="let item of col.value | dynamicKeysReader: d; let i = index">
                                                <ng-container *ngIf="!col?.pipe">
                                                    {{ item[col.multiBadgeKey ? col.multiBadgeKey : 'name'] }}
                                                </ng-container>

                                                <ng-container *ngIf="col?.pipe">
                                                    {{ item | callback: col.pipe }}
                                                </ng-container>

                                                <ng-container *ngIf="(col.value | dynamicKeysReader: d).length > i + 1">, &nbsp;</ng-container>
                                            </ng-container>
                                        </ng-container>

                                        <!-- Default -->
                                        <ng-container *ngSwitchDefault>
                                            {{ col?.pipe ? (col.value | dynamicKeysReader: d | callback: col.pipe) : (col.value | dynamicKeysReader: d) }}
                                        </ng-container>
                                    </container-element>
                                </ng-container>

                                <!-- If provided value is a function then call that function -->
                                <ng-container *ngIf="checkType(col.value) === 'function'">
                                    {{ d | callback: col.value }}
                                </ng-container>

                                <!-- Object mean template -->
                                <div *ngIf="checkType(col.value) === 'object'">
                                    <ng-container [ngTemplateOutlet]="col.value" [ngTemplateOutletContext]="{ row: d, index: i }"> </ng-container>
                                </div>
                            </div>
                        </td>
                    </ng-container>
                </tr>
            </tbody>

            <tbody *ngIf="configs.waiting.data === 'fetching' && searchKeyword.length == 0" [skeletonTabelLoader]="loaderOptions"></tbody>

            <tbody *ngIf="configs.waiting.data === 'fetching' && searchKeyword.length > 0">
                <tr>
                    <td [attr.colspan]="loaderOptions.cols">
                        <p style="text-align: center">Searching more...</p>
                        <app-loader></app-loader>
                    </td>
                </tr>
            </tbody>

            <tbody *ngIf="configs.waiting.data === 'done' && data && data.length == 0">
                <tr>
                    <td [attr.colspan]="loaderOptions.cols">
                        <app-no-data-found></app-no-data-found>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <app-ipagination [pagination]="pagination" [activePage]="filters.page" (pageChangeEvent)="setPagination($event)" *ngIf="configs.waiting.data === 'done' && this.configs.pagination === true"> </app-ipagination>
</div>
