import { Component, OnInit, Input } from '@angular/core'

@Component({
    // eslint-disable-next-line
    selector: '[skeletonTabelLoader]',
    templateUrl: './skeleton-tabel-loader.component.html',
    styleUrls: ['./skeleton-tabel-loader.component.css']
})
export class SkeletonTabelLoaderComponent implements OnInit {
    @Input()
    skeletonTabelLoader: Options

    rows: Array<number> = []

    cols: Array<number> = []

    constructor () {
    }

    ngOnInit () {
        for (let i = 0; i < this.skeletonTabelLoader.rows; i++) {
            this.rows.push(i)
        }

        for (let i = 0; i < this.skeletonTabelLoader.cols; i++) {
            if (this.skeletonTabelLoader.colSpans[i]) {
                this.cols.push(this.skeletonTabelLoader.colSpans[i])
                i = i + this.skeletonTabelLoader.colSpans[i] - 1
            }
            else {
                this.cols.push(1)
            }
        }

    }

}

export class Options {
    cols: number = 1

    rows: number = 1

    colSpans: Array<number> = []
}
