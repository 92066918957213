const identifiers = {

    rgTable: {
        searchInput: 'search-input',
        searchButton: 'search-button',
        perPage: 'per-page'
    },

    pagination: {
        nextPage: 'next-page',
        previousPage: 'previous-page'
    },

    autoComplete: {
        identifier: 'auto-complete',
        identifierUniqueId: 'data-ta-id', // This ID uniquely identifies the AutoComplete widget, if a page has more than one AutoComplete
        selectedItem: 'selected-item',
        selectedItemDel: 'selected-item-del',
        selectedItemValue: 'data-ta-value', // Each selected item's value can be fetched from this data attribute. Don't change this as values are not bin
        availableItem: 'available-item', // Each available item's value can be fetched from this data attribute. Don't change this as values are not bin
        availableItemValue: 'data-ta-value',
        searchInput: 'search-input'
    },

    dialog: {
        declineButton: 'decline-button',
        acceptButton: 'accept-button',
        doneButton: 'done-button'
    },

    customer: {
        dashboard: 'customer-dashboard',
        customerInventory: {
            customerInventoryList: {
                pageIdentifier: 'customer-inventory-list',
                selectorSearch: 'selector-search',
                addNewButton: 'add-inventory',
                rgTableSearch: 'search-input'
            },
            customerInventoryAdd: {
                pageIdentifier: 'customer-inventory-add',
                assetId: 'assetId',
                serialNo: 'serialNo',
                deviceManufacturer: 'deviceManufacturer',
                deviceModel: 'deviceModel',
                deviceModelName: 'deviceModelName',
                deviceName: 'deviceName',
                deviceProductId: 'deviceProductId',
                reFetchWarantyDetails: 'reFetchWarantyDetails',
                fetchWarantyDetails: 'fetchWarantyDetails',
                saveInventory: 'saveInventory',
                adpWarrantyDetails: 'adpWarrantyDetails',
                clwWarrantyDetails: 'clwWarrantyDetails',
                btwWarrantyDetails: 'btwWarrantyDetails',
                reFetchOverLay: 'reFetchOverLay',
                alertsMessage: {
                    info: {
                        serialNoRequired: 'serial-no-required-info',
                        manufacturerRequired: 'manufacturer-required-info'
                    }, success: {
                        inventorySave: 'save-inventory-success',
                        inventoryVerify: 'inventory-verify-success'
                    }, failed: {
                        inventoryFetchDetails: 'inventory-fetch-details-failed',
                        inventoryDiscover: 'inventory-discover-failed',
                        inventoryFormInvalid: 'inventory-form-failed',
                        inventorySave: 'save-inventory-failed',
                        inventoryVerify: 'inventory-verify-failed',
                        inventoryVerifyData: 'inventory-verify-data-failed'
                    }
                }
            },
            customerInventoryEdit: {
                pageIdentifier: 'customer-inventory-edit',
                assetId: 'assetId',
                serialNo: 'serialNo',
                deviceManufacturer: 'deviceManufacturer',
                deviceModel: 'deviceModel',
                deviceModelName: 'deviceModelName',
                deviceName: 'deviceName',
                deviceProductId: 'deviceProductId',
                reFetchWarantyDetails: 'reFetchWarantyDetails',
                fetchWarantyDetails: 'fetchWarantyDetails',
                saveInventory: 'saveInventory',
                adpWarrantyDetails: 'adpWarrantyDetails',
                clwWarrantyDetails: 'clwWarrantyDetails',
                btwWarrantyDetails: 'btwWarrantyDetails',
                reFetchOverLay: 'reFetchOverLay',
                alertsMessage: {
                    info: {
                        serialNoRequired: 'serial-no-required-info',
                        manufacturerRequired: 'manufacturer-required-info'
                    }, success: {
                        inventorySave: 'save-inventory-success',
                        inventoryVerify: 'inventory-verify-success'
                    }, failed: {
                        inventoryFetchDetails: 'inventory-fetch-details-failed',
                        inventoryDiscover: 'inventory-discover-failed',
                        inventoryFormInvalid: 'inventory-form-failed',
                        inventorySave: 'save-inventory-failed',
                        inventoryVerify: 'inventory-verify-failed',
                        inventoryVerifyData: 'inventory-verify-data-failed'
                    }
                }

            },
            customerInventoryDetails: {
                pageIdentifier: 'customer-inventory-details'
            }

        },
        customerProfile: {
            configs: {
                pageIdentifier: 'customer-profile-configs',
                assetIdCheckbox: 'enable-asset-id',
                autoApprovalLimit: 'auto-approval-limit',
                autoApproveLimitForNwcInput: 'auto-approve-limit-for-nwc',
                dmEnableAutoDecline: 'dm-enable-auto-decline',
                autoDeclineLimitForNwcInput: 'auto-decline-limit-for-nwc',
                enableNowClaimDecisionClubbing: 'enable-now-claim-decision-clubbing',
                enableSamePickupAndDropoffLocation: 'enable-same-pickup-dropoff-locations',
                enableFixedPickupLocation: 'enable-fixed-pickup-location',
                fixedPickupLocationDropdown: 'fixed-pickup-location-dropdown',
                enableFixedDropoffLocation: 'enable-fixed-dropoff-location',
                FixedDropoffLocationDropdown: 'fixed-dropoff-location-dropdown',
                enableHomeLocation: 'enable-home-location',
                enableAssetDiscoveryPrompt: 'enable-asset-discovery-prompt',
                enableAssetId: 'enable-asset-id',
                enableRequestId: 'enable-request-id',
                workOrderBulkAdd: 'work-order-bulk-add',
                workOrderBulkReceive: 'work-order-bulk-receive',
                enableWorkOrderWhiteList: 'enable-work-order-whitelist',
                enableWorkOrderBlackList: 'enable-work-order-blacklist',
                authFromGoogle: 'auth-from-google',
                authFromMicrosoft: 'auth-from-microsoft',
                authFromLocal: 'auth-from-local',
                courierPickupDropoff: 'courier-pickup-dropoff',
                enableAutoInvoices: 'enable-auto-invoices',
                enableTeacherSchool: 'enable-teacher-school',


                saveConfigsButton: 'save-configs-button',
                syncFailAlert: 'syncFailAlert',
                saveFailAlert: 'saveFailAlert',
                profileGetFailAlert: 'profileGetFailAlert',
                saveSuccessAlert: 'saveSuccessAlert'

            },
            profile: {
                pageIdentifier: 'customer-profile-profile',
                inputCustomerName: 'customer-name',
                inputCustomerLabel: 'customer-label',
                inputCustomerAbbr: 'customer-abbreviation',
                saveChangesButton: 'save-profile-button',

                saveFailAlert: 'saveFailAlert',
                profileGetFailAlert: 'profileGetFailAlert',
                saveSuccessAlert: 'saveSuccessAlert'
            },
            customFields: {
                pageIdentifier: 'custom-fields',
                fieldTitle_: 'custom-field-title',
                fieldType_: 'custom-field-type',
                modifierPrivate_: 'field-modifer-private',
                modifierPublic_: 'field-modifer-public',
                minValue_: 'custom-field-min',
                maxValue_: 'custom-field-max',
                defaultValue_: 'custom-field-default',
                buttonAddCustomField: 'add-custom-field',
                saveCustomFieldsButton: 'save-custom-fields-button',

                buttonRemoveCustomField_: 'remove-custom-field',
                buttonAddCustomFieldOption_: 'add-field-option',
                buttonRemoveCustomFieldOption_: 'remove-field-option',
                customFieldOption_: 'custom-field-option',
                isRequired_: 'is-required',

                saveFailAlert: 'saveFailAlert',
                profileGetFailAlert: 'profileGetFailAlert',
                saveSuccessAlert: 'saveSuccessAlert'
            }

        },
        schoolManager: {
            schoolAddEdit: {
                pageIdentifier: 'school-add',
                schoolName: 'school-name',
                schoolStreet: 'school-street',
                schoolZipCode: 'school-zipCode',
                schoolCountry: 'school-country',
                schoolState: 'school-state',
                schoolCity: 'school-city',
                schoolLocation: 'school-location',
                schoolMapLocation: 'school-map-location',
                schoolSave: 'save-school',
                alertsMessage: {
                    info: {

                    },
                    success: {
                        schoolAdded: 'school-added-success'
                    },
                    failed: {
                        schollExist: 'school-exist-failed',
                        fillForm: 'fill-form-failed'
                    }
                }
            },
            schoolList: {
                pageIdentifier: 'school-list',
                addNewSchoolBtn: 'add-school',
                rgTableSearch: 'search-input'
            }
        },
        myWorkOrder: {
            workOrderEdit: {
                pageIdentifier: 'work-order-edit',
                pickupLocation: 'pickup-location',
                dropOffLocation: 'drop-off-location',
                issueDescription: 'issue-description',
                homeLocation: 'home-location',
                save: 'save',
                alertsMessage: {
                    info: {

                    },
                    success: {
                        woEdited: 'work-order-edited-success'
                    },
                    failed: {
                        woEdited: 'work-order-edited-failed'
                    }
                }

            },
            workOrderList: {
                pageIdentifier: 'work-order-list',
                editBtn: 'edit-btn',
                deleteBtn: 'delete-btn',
                alertsMessage: {
                    info: {

                    },
                    success: {
                        woDeleted: 'work-order-deleted-success'
                    },
                    failed: {
                        woDeleted: 'work-order-deleted-failed'
                    }
                }
            }
        },
        submitWorkOrder: {
            pageIdentifier: 'submit-work-order',
            serialNumber: 'serial-number',
            assetId: 'asset-id',
            serialNumberInfo: 'serial-number-info',
            assetIdInfo: 'asset-id-info',
            modelInfo: 'model-info',
            deviceInfo: 'device-info',
            productIdInfo: 'product-id-info',
            manufacturerInfo: 'manufacturer-info',
            pickUpLocation: 'pick-up-location',
            dropOffLocation: 'drop-off-location',
            save: 'save',
            saveAsDraft: 'save-as-draft',
            issueDesc: 'issue-description',
            requestId: 'request-id',
            homeLocation: 'home-location',
            DiscoverAssetdeviceBrandId: 'device-brand-id',
            discoverAssetSerialNumber: 'discover-asset-serial-number',
            discoverInventory: 'discover-inventory',
            searchInventory: 'search-inventory',
            rejectDiscoverInquiry: 'reject-discover-inquiry',
            approveDiscoverInquiry: 'approve-discover-inquiry',
            alertsMessage: {
                info: {

                }, success: {
                    workOrderCreated: 'work-order-created-success'
                }, failed: {
                    workOrderCreated: 'work-order-created-failed'
                }
            }
        },
        scanReceived: {
            pageIdentifier: 'scan-received',
            currentSessionScans: 'currentSessionScans',
            receivedTodayCount: 'receivedTodayCount',
            receivablePendingCount: 'receivablePendingCount',
            inputSerialNo: 'inputSerialNo',
            buttonScan: 'buttonScan',
            buttonBulkScan: 'buttonBulkScan',
            scanPermissionDenied: 'scanPermissionDenied',
            scanFailed: 'scanFailed',
            fetchFailed: 'fetchFailed',

            remainingList: {
                pageIdentifier: 'remaining-list',
                closeButton: 'close-modal',
                buttonBulkReceiveSelected: 'buttonBulkReceiveSelected',
                bulkReceiveAll: 'bulkReceiveAll',
                selectorSearch: 'selector-search',
                rgTableSearch: 'search-input' }


        },
        bulkScanReceived: {
            pageIdentifier: 'bulk-scan-received',
            ctmLocation: 'ctmLocation',
            bulkReceiveSelected: 'bulkReceiveSelected',
            bulkReceiveAll: 'bulkReceiveAll',
            selectorSearch: 'selector-search',
            rgTableSearch: 'search-input',
            addReceivableCheckBox: 'add-receivable',
            dialogAccept: 'accept-button',
            dialogDecline: 'decline-button'


        },
        bulkUpload: {
            woBulkUpload: {
                pageIdentifier: 'bulk-upload',
                downloadCSV: 'download-csv',
                downloadXlsx: 'download-xslx',
                uploader: 'file-uploader',
                uploadFileButton: 'upload-file-button'
            }

        },
        roles: {
            pageIdentifier: 'role-add',
            roleName: 'role-name',
            addRole: 'add-role',
            saveRole: 'save-role',
            selectPermissions: 'select-permissions-list',
            selectedPermissions: 'selected-permissions-list',
            searchSelectPermissions: 'search-select-permissions-list',
            searchSelectedPermissions: 'search-selected-permissions-list',
            alertsMessage: {
                info: {

                },
                success: {
                    roleAdded: 'role-added-success',
                    save: 'save-success',
                    update: 'update-success',
                    delete: 'delete-success'
                },
                failed: {
                    roleExist: 'role-exist-failed',
                    fillForm: 'fill-form-failed',
                    noPermission: 'no-permission-failed',
                    saveUpdate: 'save-update-failed',
                    delete: 'delete-failed'
                }
            },
            rolesList: {
                pageIdentifier: 'school-list',
                addNewSchoolBtn: 'add-school',
                rgTableSearch: 'search-input'
            }
        },
        employees: {
            employeeAdd: {
                pageIdentifier: 'employee-add',
                employeeName: 'employee-name',
                employeeEmail: 'employee-email',
                employeeAuthProviders: 'auth-providers',
                employeeRoles: 'employee-roles',
                employeePermissions: 'employee-permissions',
                employeeSchools: 'employee-schools',
                employeeContacts: 'employee-contacts',
                emergencyContact: 'emergency-contact',
                employeeContact: 'employee-contact',
                employeeDefaultRole: 'default-role',
                employeeAdress: 'employee-adress',
                employeeSave: 'save-employee',
                rolesSuggestionInput: 'roles-suggestion-input',
                permissionSuggestionInput: 'permission-suggestion-input',
                schoolSuggestionInput: 'school-suggestion-input'
            },
            employeeEdit: {
                pageIdentifier: 'employee-add',
                employeeAdress: 'employee-adress',
                employeeName: 'employee-name',
                employeeEmail: 'employee-email',
                employeeAuthProviders: 'auth-providers',
                employeeRoles: 'employee-roles',
                employeePermissions: 'employee-permissions',
                employeeSchools: 'employee-schools',
                employeeContacts: 'employee-contacts',
                emergencyContact: 'emergency-contact',
                employeeContact: 'employee-contact',
                employeeDefaultRole: 'default-role',
                employeeSave: 'save-employee',
                rolesSuggestionInput: 'roles-suggestion-input',
                permissionSuggestionInput: 'permission-suggestion-input',
                schoolSuggestionInput: 'school-suggestion-input'
            },
            employeeAddEdit: {
                pageIdentifier: 'employee-add-edit',
                employeeAdress: 'employee-adress',
                employeeName: 'employee-name',
                employeeEmail: 'employee-email',
                employeeAuthProviders: 'auth-providers',
                employeeRoles: 'employee-roles',
                employeePermissions: 'employee-permissions',
                employeeSchools: 'employee-schools',
                employeeContacts: 'employee-contacts',
                emergencyContact: 'emergency-contact',
                employeeContact: 'employee-contact',
                employeeDefaultRole: 'default-role',
                employeeSave: 'save-employee',
                alertsMessage: {
                    info: {

                    }, success: {
                        employeeEditCreate: 'employee-edit-create-success'
                    }, failed: {
                        employeeEditCreate: 'employee-edit-create-failed'
                    }
                }
            },
            employeeList: {
                pageIdentifier: 'employee-list',
                addEmployee: 'add-employee',
                editBtn: 'edit-btn'
            }
        }
    },

    workshop: {
        dashboard: 'workshop-dashboard',
        scanInBoundReady: {
            pageIdentifier: 'scan-inbound-ready',
            scanCompleted: 'scan-completed',
            scanRemanining: 'scan-remaning',
            scanDevice: 'scan-device',
            scanViaSerial: 'scan-via-serial',
            serialNo: 'serial-no',
            scanSuccessfull: 'scan-successfull',
            alertsMessage: {
                info: {

                }, success: {
                    deviceScan: 'device-scan-success'
                }, failed: {
                    deviceScan: 'device-scan-failed'
                }
            }
        },
        inBoundReady: {
            pageIdentifier: 'inbound-ready',
            scanCompleted: 'scan-completed',
            scanRemanining: 'scan-remaning',
            scanDevice: 'scan-device',
            scanViaSerial: 'scan-via-serial',
            serialNo: 'serial-no',
            scanSuccessfull: 'scan-successfull',
            alertsMessage: {
                info: {

                }, success: {
                    deviceScan: 'device-scan-success'
                }, failed: {
                    deviceScan: 'device-scan-failed'
                }
            }
        },
        npItemMgmt: {
            addEditNpItem: {
                pageIdentifier: 'add-edit-qc-item',
                npItemTitle: 'np-item-title',
                npItemDescription: 'np-item-description',
                npItemClaimText: 'np-item-claim-text',
                saveNpItemBtn: 'save-np-item-btn',
                importNewRuleGroupBtn: 'import-rule-group-btn',
                ruleGroupAccessType: 'rule-group-type',
                addRuleGroupBtn: 'add-rule-group-btn',
                editRuleGroupBtn: 'edit-rule-group-btn',
                availableRule: 'available-rule',
                selectedRule: 'selected-rule',
                addRuleBtn: 'add-rule-btn',
                deleteRuleBtn: 'delete-rule-btn',
                deleteRuleGroupBtn: 'delete-rule-group-btn',
                ruleGroupTitle: 'rule-group-title',
                saveRuleBtn: 'save-rule-btn',
                ruleType: 'rule-type',
                searchAvailableRule: 'search-available-rule',
                searchSelectedRule: 'search-selected-rule',
                ruleGroupDescription: 'rule-group-description',
                ruleGroupType: 'rule-group-type',
                saveRuleGroup: 'save-rule-group',
                importSelectedRuleGroupBtn: 'import-selected-rule-group-btn',
                AddNewRuleGroupBtn: 'add-new-rule-group-btn',
                alertsMessage: {
                    info: {

                    }, success: {
                        npItemEditCreate: 'np-item-edit-create-success',
                        ruleGroupAddEdit: 'rule-group-edit-create-success',
                        ruleAddEdit: 'rule-add-edit-success'
                    }, failed: {
                        npItemEditCreate: 'np-item-edit-create-failed',
                        ruleGroupAddEdit: 'rule-group-edit-create-failed',
                        ruleAddEdit: 'rule-add-edit-failed'
                    }
                }
            },
            listNpItem: {
                pageIdentifier: 'list-np-item',
                addNew: 'add-new',
                searchInputTable: 'search-input-table'
            }
        },
        qcItemMgmt: {
            addEditQcItem: {
                pageIdentifier: 'add-edit-qc-item',
                qcItemTitle: 'qc-item-title',
                qcItemDescription: 'qc-item-description',
                qcItemClaimText: 'qc-item-claim-text',
                saveQcItemBtn: 'save-qc-item-btn',
                importNewRuleGroupBtn: 'import-rule-group-btn',
                ruleGroupAccessType: 'rule-group-type',
                addRuleGroupBtn: 'add-rule-group-btn',
                editRuleGroupBtn: 'edit-rule-group-btn',
                availableRule: 'available-rule',
                selectedRule: 'selected-rule',
                addRuleBtn: 'add-rule-btn',
                deleteRuleBtn: 'delete-rule-btn',
                deleteRuleGroupBtn: 'delete-rule-group-btn',
                ruleGroupTitle: 'rule-group-title',
                saveRuleBtn: 'save-rule-btn',
                ruleType: 'rule-type',
                searchAvailableRule: 'search-available-rule',
                searchSelectedRule: 'search-selected-rule',
                ruleGroupDescription: 'rule-group-description',
                ruleGroupType: 'rule-group-type',
                saveRuleGroup: 'save-rule-group',
                importSelectedRuleGroupBtn: 'import-selected-rule-group-btn',
                AddNewRuleGroupBtn: 'add-new-rule-group-btn',
                alertsMessage: {
                    info: {

                    }, success: {
                        qcItemEditCreate: 'qc-item-edit-create-success',
                        ruleGroupAddEdit: 'rule-group-edit-create-success',
                        ruleAddEdit: 'rule-add-edit-success'
                    }, failed: {
                        qcItemEditCreate: 'qc-item-edit-create-failed',
                        ruleGroupAddEdit: 'rule-group-edit-create-failed',
                        ruleAddEdit: 'rule-add-edit-failed'
                    }
                }
            },
            listQcItem: {
                pageIdentifier: 'list-qc-item',
                addNew: 'add-new',
                searchInputTable: 'search-input-table'
            }
        },
        issueCardMgmt: {
            addEditIssueCard: {
                pageIdentifier: 'add-edit-issue-card',
                issueCardTitle: 'issue-card-title',
                issueCardDescription: 'issue-card-description',
                issueCardClaimText: 'issue-card-claim-text',
                saveIssueCardBtn: 'save-issue-card-btn',
                importNewRuleGroupBtn: 'import-rule-group-btn',
                addRuleGroupBtn: 'add-rule-group-btn',
                editRuleGroupBtn: 'edit-rule-group-btn',
                availableRule: 'available-rule',
                selectedRule: 'selected-rule',
                addRuleBtn: 'add-rule-btn',
                deleteRuleBtn: 'delete-rule-btn',
                deleteRuleGroupBtn: 'delete-rule-group-btn',
                ruleGroupTitle: 'rule-group-title',
                saveRuleBtn: 'save-rule-btn',
                ruleType: 'rule-type',
                searchAvailableRule: 'search-available-rule',
                searchSelectedRule: 'search-selected-rule',
                ruleGroupDescription: 'rule-group-description',
                ruleGroupType: 'rule-group-type',
                saveRuleGroup: 'save-rule-group',
                importSelectedRuleGroupBtn: 'import-selected-rule-group-btn',
                AddNewRuleGroupBtn: 'add-new-rule-group-btn',
                alertsMessage: {
                    info: {

                    }, success: {
                        issueCardEditCreate: 'issue-card-edit-create-success',
                        ruleGroupAddEdit: 'rule-group-edit-create-success',
                        ruleAddEdit: 'rule-add-edit-success'
                    }, failed: {
                        issueCardEditCreate: 'issue-card-edit-create-failed',
                        ruleGroupAddEdit: 'rule-group-edit-create-failed',
                        ruleAddEdit: 'rule-add-edit-failed'
                    }
                }
            },
            listIssueCard: {
                pageIdentifier: 'list-issue-card',
                addNew: 'add-new',
                searchInputTable: 'search-input-table'
            }
        },
        workShopMgmt: {
            addEditWorkShop: {
                pageIdentifier: 'add-edit-workshop',
                workshopName: 'workshop-name',
                workShopStreet: 'workshop-street',
                workShopZip: 'workshop-zip',
                workShopCountry: 'workshop-country',
                workShopState: 'workshop-state',
                workShopCity: 'workshop-city',
                workShopOwnerName: 'workshop-owner-name',
                workShopOwnerEmail: 'workshop-owner-email',
                workShopAuthProvider: 'workshop-auth-provider',
                saveWorkShop: 'save-workshop',
                alertsMessage: {
                    info: {

                    }, success: {
                        workShopEditCreate: 'workshop-edit-create-success'
                    }, failed: {
                        workShopEditCreate: 'workshop-edit-create-failed'
                    }
                }
            },
            listWorkShop: {
                pageIdentifier: 'list-workshop',
                addNew: 'add-new'

            }
        },
        customerInventory: {
            customerInventoryList: {
                pageIdentifier: 'customer-inventory-list',
                selectorSearch: 'selector-search',
                addNewButton: 'add-inventory',
                rgTableSearch: 'search-input'
            },
            customerInventoryAdd: {
                pageIdentifier: 'customer-inventory-add',
                assetId: 'assetId',
                serialNo: 'serialNo',
                deviceManufacturer: 'deviceManufacturer',
                deviceModel: 'deviceModel',
                deviceModelName: 'deviceModelName',
                deviceName: 'deviceName',
                deviceProductId: 'deviceProductId',
                reFetchWarantyDetails: 'reFetchWarantyDetails',
                fetchWarantyDetails: 'fetchWarantyDetails',
                saveInventory: 'saveInventory',
                adpWarrantyDetails: 'adpWarrantyDetails',
                clwWarrantyDetails: 'clwWarrantyDetails',
                btwWarrantyDetails: 'btwWarrantyDetails',
                reFetchOverLay: 'reFetchOverLay',
                alertsMessage: {
                    info: {
                        serialNoRequired: 'serial-no-required-info',
                        manufacturerRequired: 'manufacturer-required-info'
                    }, success: {
                        inventorySave: 'save-inventory-success',
                        inventoryVerify: 'inventory-verify-success'
                    }, failed: {
                        inventoryFetchDetails: 'inventory-fetch-details-failed',
                        inventoryDiscover: 'inventory-discover-failed',
                        inventoryFormInvalid: 'inventory-form-failed',
                        inventorySave: 'save-inventory-failed',
                        inventoryVerify: 'inventory-verify-failed',
                        inventoryVerifyData: 'inventory-verify-data-failed'
                    }
                }
            },
            customerInventoryAddEdit: {
                pageIdentifier: 'customer-inventory-edit',
                assetId: 'assetId',
                serialNo: 'serialNo',
                customerName: 'customer-name',
                deviceManufacturer: 'deviceManufacturer',
                deviceModel: 'deviceModel',
                deviceModelName: 'device-model-name',
                deviceName: 'device-name',
                productId: 'device-product-id',
                saveInventory: 'save-inventory',
                adpWarrantyDetails: 'adpWarrantyDetails',
                clwWarrantyDetails: 'clwWarrantyDetails',
                btwWarrantyDetails: 'btwWarrantyDetails',
                discoverInventoryBtn: 'discover-inventory-btn',
                verifyInventoryBtn: 'verify-inventory-btn',
                fetchWarrantyBtn: 'fetch-warranty-btn',
                editWarrantyDetailsManually: 'edit-warranty-details-manually',
                alertsMessage: {
                    info: {
                    }, success: {
                        inventorySave: 'save-inventory-success',
                        discoverInventory: 'discover-inventory-success'
                    }, failed: {
                        inventorySave: 'save-inventory-failed',
                        discoverInventory: 'discover-inventory-failed'
                    }
                }

            },
            customerInventoryDetails: {
                pageIdentifier: 'customer-inventory-details'
            }

        },
        customers: {
            pageIdentifier: 'workshop-customers',
            addEditCustomer: {
                pageIdentifier: 'customer-add-edit',
                customerDetailsTab: 'customer-details-tab',
                customerSchoolsTab: 'customer-schools-tab',
                customerEmployeesTab: 'customer-employees-tab',
                customerDetails: {
                    customerName: 'customer-name',
                    customerAbbr: 'customer-abbr',
                    customerLabel: 'customer-label',
                    customerStreet: 'customer-street',
                    customerZipCode: 'customer-zipCode',
                    customerCountry: 'customer-country',
                    customerState: 'customer-state',
                    customerCity: 'customer-city',
                    customerLocation: 'customer-location',
                    customerZone: 'customer-zone',
                    customerMapLocation: 'customer-map-location',
                    customerAddress: 'customer-address',
                    customerPrimaryScan: 'customer-primary-scan',
                    customerSave: 'save-customer',
                    ownerAdminDetails: {
                        customerOwnerFullName: 'customer-owner-fullname',
                        customerOwnerEmailAddress: 'customer-owner-email-address',
                        customerOwnerAuthSuggestionInput: 'customer-owner-auth-suggestion-input'
                    },
                    otherDetails: {
                        enableRequestId: 'enable-request-id',
                        enableNowAutoApproval: 'enable-now-auto-approval',
                        enableNowAutoDecline: 'enable-now-auto-decline',
                        enableAssetId: 'enable-asset-id',
                        enableHomeLocation: 'enable-home-location',
                        enableDropOffLoication: 'enable-drop-off-location',
                        enableFixedPickUpLocation: 'enable-fixed-pickup-location',
                        enableFixedDropOffLocation: 'enable-fixed-dropoff-location',
                        enablePeripheralSupport: 'enable-peripheral-support',
                        enableCourierPickUpDropOff: 'enable-courier-pickup-dropoff',
                        enableOwnPickUpDropOff: 'enable-own-pickup-dropoff',
                        enableTeacherSchool: 'enable-teacher-school',
                        enableWorkOrderWhiteList: 'enable-work-order-white-list',
                        enableWorkOrderBlackList: 'enable-work-order-black-list',
                        enableOnSiteRepair: 'enable-on-site-repair',
                        enableAllBulkWorkOrderUpload: 'enable-all-bulk-work-order-upload',
                        enableAssetDiscoveryPrompt: 'enable-asset-discovery-prompt',
                        enableBulkWorkOrderReceivals: 'enable-bulk-work-order-receivals',
                        enableGoogleAuthentication: 'enable-google-authentication',
                        enableMicrosoftAuthentication: 'enable-microsoft-authentication',
                        enableRepairGenieAuthentication: 'enable-repair-genie-authentication',
                        enableAutoInvoices: 'enable-auto-invoices'
                    },
                    alertsMessage: {
                        info: {

                        }, success: {
                            customerCreate: 'customer-create-success'
                        }, failed: {
                            customerCreate: 'customer-create-failed'
                        }
                    }
                },
                ctmSchool: {
                    ctmSchoolList: {
                        pageIdentifier: 'ctm-school-list',
                        addNewBtn: 'add-new-ctm-school-btn',
                        rgTableSearch: 'search-input',
                        editBtn: 'edit-btn'
                    },
                    ctmSchoolAddEdit: {
                        pageIdentifier: 'ctm-school-add-edit',
                        schoolName: 'school-name',
                        schoolStreet: 'school-street',
                        schoolZipCode: 'school-zipCode',
                        schoolCountry: 'school-country',
                        schoolState: 'school-state',
                        schoolCity: 'school-city',
                        schoolLocation: 'school-location',
                        schoolZone: 'school-zone',
                        schoolMapLocation: 'school-map-location',
                        schoolSave: 'save-school',
                        alertsMessage: {
                            info: {

                            },
                            success: {
                                schoolAdded: 'ctm-school-added-success'
                            },
                            failed: {
                                schollExist: 'ctm-school-exist-failed',
                                fillForm: 'ctm-fill-form-failed'
                            }
                        }
                    }
                }
            },
            customerList: {
                pageIdentifier: 'customer-list',
                addCustomerBtn: 'add-customer-btn',
                rgTableSearch: 'search-input',
                editBtn: 'edit-btn'
            }
        },
        scanReceived: {
            pageIdentifier: 'scan-received',
            currentSessionScans: 'currentSessionScans',
            receivablePendingCount: 'receivablePendingCount',
            inputSerialNo: 'inputSerialNo',
            buttonScan: 'buttonScan',
            successMsg: 'success-msg',
            failMsg: 'fail-msg',

            scanFailed: 'scanFailed',
            fetchFailed: 'fetchFailed',

            remainingList: {
                pageIdentifier: 'remaining-list',
                closeButton: 'close-modal',
                selectorSearch: 'selector-search',
                rgTableSearch: 'search-input'
            }


        },
        driver: {
            scanDevices: {
                pageIdentifier: 'driver-scan-page',
                scanPickUpDropOffButton: 'submitScan',
                totalDevices: 'total-devices',
                pickUpRadiobutton: 'pickup-radiobutton',
                DropOffRadiobutton: 'dropoff-radiobutton',
                successfulScans: 'suceess-scans',
                pendingScans: 'pending-scans',
                endTripButton: 'end-trip-button',
                leftBox: 'left-box',
                leftSearchBar: 'left-searchbar',
                scanWorkOrder_: 'scan-work-order-',
                serialNo_: 'serial-no-',
                model_: 'device-model-',
                rightSearchbar: 'right-searchbar',
                rightBox: 'right-box',
                reverseScanWorkOrder_: 'reverse-scan-',
                acceptButton: 'accept-button',
                rejectButton: 'decline-button'

            },
            chooseSite: {
                pageIdentifier: 'choose-site-page',
                selectCustomer: 'select-customer',
                selectLocation: 'select-location',
                startScanningButton: 'start-scanning-button'

            },
            closeTrip:
            {
                pageIdentifier: 'choose-site-page',
                pickupCount: 'pickup-count',
                dropoffCount: 'dropoff-count',
                confirmCloseTrip: 'confirm-close-trip',
                schoolRepName: 'school-representive-name',
                acceptButton: 'accept-button',
                rejectButton: 'decline-button'
            },
            dailyTrips: {
                pageIdentifier: 'daily-trips-page',
                tripStatusOpen: 'trip-status-open',
                tripStatusClosed: 'trip-status-closed',
                buttonAddVisit: 'button-add-visit'


            }
        },
        submitWorkOrder: {
            pageIdentifier: 'submit-work-order',
            customerList: 'customer-list',
            serialNumber: 'serial-number',
            assetId: 'asset-id',
            serialNumberInfo: 'serial-number-info',
            assetIdInfo: 'asset-id-info',
            modelInfo: 'model-info',
            deviceInfo: 'device-info',
            productIdInfo: 'product-id-info',
            manufacturerInfo: 'manufacturer-info',
            pickUpLocation: 'pick-up-location',
            dropOffLocation: 'drop-off-location',
            save: 'save',
            saveAsDraft: 'save-as-draft',
            issueDesc: 'issue-description',
            requestId: 'request-id',
            homeLocation: 'home-location',
            DiscoverAssetdeviceBrandId: 'device-brand-id',
            discoverAssetSerialNumber: 'discover-asset-serial-number',
            discoverInventory: 'discover-inventory',
            searchInventory: 'search-inventory',
            rejectDiscoverInquiry: 'reject-discover-inquiry',
            approveDiscoverInquiry: 'approve-discover-inquiry',
            alertsMessage: {
                info: {

                }, success: {
                    workOrderCreated: 'work-order-created-success'
                }, failed: {
                    workOrderCreated: 'work-order-created-failed'
                }
            }
        },
        roles: {
            pageIdentifier: 'role-add',
            roleName: 'role-name',
            addRole: 'add-role',
            saveRole: 'save-role',
            selectPermissions: 'select-permissions-list',
            selectedPermissions: 'selected-permissions-list',
            searchSelectPermissions: 'search-select-permissions-list',
            searchSelectedPermissions: 'search-selected-permissions-list',
            alertsMessage: {
                info: {

                },
                success: {
                    roleAdded: 'role-added-success',
                    save: 'save-success',
                    update: 'update-success',
                    delete: 'delete-success'
                },
                failed: {
                    roleExist: 'role-exist-failed',
                    fillForm: 'fill-form-failed',
                    noPermission: 'no-permission-failed',
                    saveUpdate: 'save-update-failed',
                    delete: 'delete-failed'
                }
            },
            rolesList: {
                pageIdentifier: 'school-list',
                addNewSchoolBtn: 'add-school',
                rgTableSearch: 'search-input'
            }
        },
        cellTechEmployee: {
            employeeAddEdit: {
                pageIdentifier: 'cell-tech-employee-add-edit',
                employeeBamboNumber: 'employee-bamboo-no',
                employeeName: 'employee-name',
                employeeJoinDate: 'employee-join-date',
                employeeJoinComment: 'employee-join-comment',
                employeePrimaryWorkShop: 'employee-primary-workshop',
                employeeEmail: 'employee-email',
                employeeAuthProviders: 'auth-providers',
                employeeRoles: 'employee-roles',
                employeePermissions: 'employee-permissions',
                employeeDefaultRole: 'default-role',
                employeeSave: 'save-employee',
                alertsMessage: {
                    info: {

                    }, success: {
                        employeeEditCreate: 'cell-tech-employee-edit-create-success'
                    }, failed: {
                        employeeEditCreate: 'cell-tech-employee-edit-create-failed'
                    }
                },
                joinedWorkShop: {
                    joinWorkShopBtn: 'join-workshop-btn',
                    changeJoinDate: 'change-join-date',
                    leaveWorkShop: 'leave-workshop',
                    deleteWorkShop: 'delete-workshop',
                    workshop: 'workshop',
                    joinDate: 'join-date',
                    joinComment: 'join-comment',
                    leaveDate: 'leave-date',
                    leaveComment: 'leave-comment',
                    saveLeaveWorkShop: 'save-leave-workshop',
                    saveJoinWorkShop: 'save-join-workshop',
                    alertsMessage: {
                        info: {

                        }, success: {
                            joinWorkShopSuccess: 'cell-tech-employee-join-workshop-success',
                            leaveWorkShopSuccess: 'cell-tech-employee-leave-workshop-success',
                            deleteWorkShopSuccess: 'cell-tech-employee-delete-workshop-success'
                        }, failed: {
                            joinWorkShopFailed: 'cell-tech-employee-join-workshop-failed',
                            leaveWorkShopFailed: 'cell-tech-employee-leave-workshop-failed',
                            deleteWorkShopFailed: 'cell-tech-employee-delete-workshop-failed'
                        }
                    }
                }
            },
            employeeList: {
                pageIdentifier: 'workshop-employee-list',
                addEmployee: 'add-employee',
                editBtn: 'edit-btn'
            }
        }
    },

    general: {
        login: 'Login'
    }
}

export default identifiers
