import { HttpClient, HttpEventType } from '@angular/common/http'
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core'
import { firstValueFrom, map } from 'rxjs'
import { LoadingStatusEnum } from 'src/app/enums/LoadingStatusEnum'
import { IAlertService } from 'src/app/libs/ialert/ialerts.service'
import { ApiService } from 'src/app/services/api.service'
import { ConstantsService } from 'src/app/services/constants.service'
import { apis } from 'src/environments/environment'
import { FileTypesEnum } from './../../enums/FileTypesEnum'

@Component({
    selector: 'app-rg-file-uploader',
    templateUrl: './rg-file-uploader.component.html',
    styleUrls: ['./rg-file-uploader.component.scss']
})
export class RgFileUploaderComponent implements OnInit {

    @Input() fd: FormData = new FormData()

    @Input() url: string

    @Input() options: RgFileUploaderOptions

    @ViewChild('fileInputRef') fileInputRef: ElementRef

    @ViewChild('uploaderWrapper') uploaderWrapper: ElementRef

    filesList: FileList | null = null

    validationResult: string | true

    @Output()
    onComplete = new EventEmitter()

    /**
     * UI Elements
     */
    waiting: {
        fileData: LoadingStatusEnum,
        bulkUpload: LoadingStatusEnum
    }

    lang: any

    /**
     * This will display the upload progress in percentage
     */
    uploadProgress: number = 0

    constructor (
        public alert: IAlertService,
        private renderer: Renderer2,
        private cdRef: ChangeDetectorRef,
        private api: ApiService,
        private http: HttpClient,
        public cs: ConstantsService
    ) {
        this.initTranslation().then(this.initProperties.bind(this))
    }

    ngOnInit () {
    }

    initProperties () {
        this.waiting = {
            fileData: LoadingStatusEnum.NONE,
            bulkUpload: LoadingStatusEnum.NONE
        }
    }

    initTranslation (): Promise<any> {
        const transSub = this.api
            .translate('common.file-uploader')
            .pipe(
                map((d: any) => {
                    this.lang = d
                })
            )
        
        return firstValueFrom<any>(transSub)
    }

    triggerClickOnFile () {
        this.fileInputRef.nativeElement.click()
    }

    /**
     * This method will actually upload file to the provided URL
     * @param files 
     * @returns ture on success otherwise Error message in string
     */
    uploadFile (): true | string {
        const validationResult = this.validateFile(this.filesList)

        if (validationResult !== true) {
            this.onComplete.emit(validationResult)
            
            return validationResult
        }

        if (this.filesList == null) {
            return 'invalid'
        }

        const file: File = this.filesList.item(0) as unknown as File
        this.fd.append('file', file, file.name)
        
        this.resetUploadProgress()
        
        const url = `${ apis.baseUrl }/${ this.url }`
        this.http.post(url, this.fd, {
            reportProgress: true,
            observe: 'events'
        }).subscribe( (resp: any) => {
            if (resp.type === HttpEventType.Response) {
                this.onComplete.emit(resp.body)
            }
            
            if (resp.type === HttpEventType.UploadProgress) {
                const percentDone = Math.round(100 * resp.loaded / resp.total)
                this.uploadProgress = percentDone
            }
        })

        return true
    }

    reUploadFile () {
        this.fileInputRef.nativeElement.value = null
        this.cdRef.detectChanges()
        this.fileInputRef.nativeElement.click()
    }

    resetUploadProgress () {
        this.uploadProgress = 0
    }

    onDrop (e: any) {
        e.stopPropagation()
        e.preventDefault()
        this.renderer.removeClass(this.uploaderWrapper.nativeElement, 'dragging-over')

        if (!e.dataTransfer) {
            return this.alert.error(this.lang.unknown_load)
        }

        if (!e.dataTransfer.files || e.dataTransfer.files.length == 0) {
            return this.alert.error(this.lang.no_files)
        }
        console.log('filessss', e.d)
        this.filesList = e.dataTransfer.files
    }

    onDrag (e: any) {
        // e.stopPropagation();
        e.preventDefault()
    }

    onDragLeave (e: any) {
        e.preventDefault()
        this.renderer.removeClass(this.uploaderWrapper.nativeElement, 'dragging-over')
    }

    onDragOver (e: any) {
        e.preventDefault()
        this.renderer.addClass(this.uploaderWrapper.nativeElement, 'dragging-over')
    }

    onFilesChange (e: Event) {
        const element = e.currentTarget as HTMLInputElement
        this.filesList = element.files

        this.validationResult = this.validateFile(this.filesList)
        this.resetUploadProgress()
    }

    /**
     * This method will validate File 
     * 
     * @return Returns true if validated otherwise returns the
     * reason for being invalid in plain string
     */
    validateFile (files: FileList | null): true | string {
        if (!files) {
            return this.lang.no_file_selected
        }

        if (files.length > 1) {
            return this.lang.only_one_file
        }

        if (files.length == 0) {
            return this.lang.no_file_selected
        }

        const file = files[0]
        const result = this.isValidCsvFile(file)
        if (result !== true) {
            const excelResult = this.isValidExcelFile(file)
            if (excelResult !== true) {
                return excelResult
            }
        }

        const maxAllowedSize = 1024 * 1024 * 50
        if (file.size > maxAllowedSize) {
            return this.lang.invalid_file_size
        }

        return true
    }

    isValidExcelFile (file: File): true | string {
        const allowedExtensionsExcel = ['.xlsx', '.xls']

        const extension = file.name.substring(file.name.lastIndexOf('.'))
        if (allowedExtensionsExcel.indexOf(extension) < 0) {
            return this.lang.invalid_file
        }

        const allowedTypesExcel = [
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // xlsx
            'application/vnd.ms-excel' // xls
        ]
        if (allowedTypesExcel.indexOf(file.type) < 0) {
            return this.lang.invalid_file
        }

        return true
    }

    isValidCsvFile (file: File): true | string {
        const allowedExtensionsExcel = new Array('.csv')

        const extension = file.name.substring(file.name.lastIndexOf('.'))
        if (allowedExtensionsExcel.indexOf(extension) < 0) {
            return this.lang.invalid_file
        }

        const allowedTypesExcel = [
            'text/plain',
            'text/x-csv',
            'application/csv',
            'application/x-csv',
            'text/csv',
            'text/comma-separated-values',
            'text/x-comma-separated-values',
            'text/tab-separated-values'
        ]
        if (allowedTypesExcel.indexOf(file.type) < 0) {
            return this.lang.invalid_file
        }

        return true
    }

    getFileType (file: File | null) {
        if (file == null) {
            return 'unknown'
        }
        
        let resultFileType: FileTypesEnum | 'unknown' = 'unknown'
        
        Object.keys(FileTypesEnum).map((type: string) => {
            if ((this.cs.FILE_MIME_TYPES as any)[type].indexOf(file.type) > -1) {
                resultFileType = type as unknown as FileTypesEnum
            }
        })

        return resultFileType
    }

    get LoadingStatusEnum () {
        return LoadingStatusEnum
    }
}

export interface RgFileUploaderOptions {
    /**
     * maximum allowed size of the file in MBs
     */
    maxSize: number

    /**
     * Allowed file types, listed in an array
     */
    allowedFileTypes: Array<string>

    /**
     * 
     */
}
