export const environment = {
    production: false
}

export const apis = {
    // baseUrl: 'http://localhost:8000/api/v4',
    baseUrl: 'https://rgv4dev.repairgenie.net/backend/api/v4',
    googleApiKey: '',
    googleCaptchaSiteKey: ''
}

export const socialLoginUrls = {
    google: `${apis.baseUrl}/public/login/google`,
    facebook: `${apis.baseUrl}/public/login/facebook`
}


export const firebaseConfig = {
    apiKey: 'AIzaSyCynMycY57mzKOTq2sWYbrcrog8wIpIAHc',
    authDomain: 'rgv4-dev.firebaseapp.com',
    projectId: 'rgv4-dev',
    storageBucket: 'rgv4-dev.appspot.com',
    messagingSenderId: '671556903383',
    appId: '1:671556903383:web:7a628666a8cc1fd1112b92',
    measurementId: 'G-ESPNVXQCNQ',
    vapidKey: 'BBVOLGfqCFkbJiPgPoWkRoodz0YtYqbJJEOAtJSVU37Lmgh4_Ick7sTcvXP4fcWsc9Vg4pHh4tYQ0k0YtxZQYU8'
}

export const appURL = 'http://localhost:4200/'
