import { Injectable } from '@angular/core'
import { overrideDefaultValueAccessor } from '../overrides/default-value-accessor'

@Injectable()
export class OverridesService {

    init () {
        overrideDefaultValueAccessor()
    }
}
