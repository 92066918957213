import { DOCUMENT } from '@angular/common'
import { RgDialogComponent } from './rg-dialog.component'
import { Injectable, ComponentFactoryResolver, ApplicationRef, Injector, EmbeddedViewRef, ComponentRef, Inject, TemplateRef, Renderer2 } from '@angular/core'
import { ApiService } from 'src/app/services/api.service'
import { NgForm } from '@angular/forms'

@Injectable()
export class DialogService {
    data: any = {}

    defaultConfigs: DialogConfigs = {
        type: DialogType.Confirm,
        title: 'Confirm',
        statement: 'Are you sure to continue?',
        template: null,
        templateData: {},
        confirm: {
            text: 'Yes'
        } as DialogButton,
        decline: {
            text: 'No'
        } as DialogButton,
        done: {
            text: 'Ok'
        } as DialogButton,
        canceled: {
            text: 'Canceled'
        } as DialogButton,
        styles: { }
    }

    constructor (
        private api: ApiService,
        private cfr: ComponentFactoryResolver,
        private applicationRef: ApplicationRef,
        private injector: Injector,
        @Inject(DOCUMENT) private document: Document
    ) {

    }

    confirm (configs: DialogConfigs) {
        return this.showDialog(configs, DialogType.Confirm)
    }

    dialog (configs: DialogConfigs) {
        return this.showDialog(configs, DialogType.Dialog)
    }

    showDialog (configs: DialogConfigs, type: DialogType) {
        const newConfigs: DialogConfigs = this.cloneWithDefault(configs, type)

        const ref: RgDialogComponent = this.createDialog(newConfigs)

        return ref
    }

    cloneWithDefault (configs: any, type: DialogType) {
        const defaultConf = this.defaultConfigs
        Object.keys(configs)
        .filter( key => key !== 'template') // cloning templates actually loos refence
        .map( (key: string) => {
            if (typeof configs[key as keyof DialogConfigs] === 'object') {
                configs[key] = { ...(defaultConf as any)[key], ...configs[key] }
            }
        })

        configs.type = type

        return { ...defaultConf, ...configs }
    }

    private createDialog (configs: DialogConfigs): RgDialogComponent {
        const component = RgDialogComponent
        // create a component reference
        const componentRef: ComponentRef<RgDialogComponent> = this.cfr.resolveComponentFactory(component).create(this.injector)

        const instance = componentRef.instance
        instance.componentRef = componentRef
        instance.addClasses('back-office-panel')

        /**
         * Assign / Initiate configurations
         */
        instance.configs = configs
        if (configs.styles) {
            instance.styles = configs.styles
        }

        if (configs.template != null) {
            instance.dynamicTemplate = configs.template as TemplateRef<any>
        }

        // attach component to the appRef so that so that it will be dirty checked.
        this.applicationRef.attachView(componentRef.hostView)

        // get DOM element from component
        const domElem = (componentRef.hostView as EmbeddedViewRef<any>)
            .rootNodes[0] as HTMLElement

        this.document.body.appendChild(domElem)

        // We can also load component in a div as well
        // const newNode = document.createElement('div')
        // newNode.id = 'placeholder'
        // document.getElementById('container')!.appendChild(newNode)

        // const ref = this.cfr.resolveComponentFactory(component).create(this.injector, [], newNode)
        // console.log('all good', ref)
        // this.applicationRef.attachView(ref.hostView)

        return componentRef.instance as RgDialogComponent
    }

    closeDialog (componentRef: ComponentRef<any>) {
        this.applicationRef.detachView(componentRef.hostView)
        componentRef.destroy()
    }
}

export enum DialogType {
    'Confirm', 'Dialog'
}

export interface DialogConfigs {
    type: DialogType
    title: string
    statement: string
    template: TemplateRef<any> | null
    templateData: object
    confirm: DialogButton
    decline: DialogButton
    done: DialogButton,
    canceled: DialogButton,
    styles: any
}

export interface DialogButton {
    text: string
    class?: string
    onClick: (data?: any) => void
}
