<div class="dialog-back" (click)="close()"></div>
<div class="diaglog-box" *ngIf="configs">
    <div class="title">
        <h1>{{ configs.title }}</h1>
        <i class="fas fa-times" (click)="this.configs.canceled.onClick(); close()"></i>
    </div>

    <form #form="ngForm">
        @if (dynamicTemplate === null) {
            <p class="statement">
                {{ configs.statement }}
            </p>
        } @else {
            <ng-container *ngTemplateOutlet="dynamicTemplate; context: { data: this.ds.data }"></ng-container>
        }
        <div class="actions">
            <!-- <button class="btn-cancel" (click)="close()">Cancel</button> -->
            <ng-container *ngIf="configs.type == DialogType.Confirm">
                <button class="btn-decline" [class]="configs.decline.class" (click)="configs.decline.onClick()" [attr.data-ta]="identifiers.declineButton">
                    {{ configs.decline.text }}
                </button>
                <button class="btn-confirm" [class]="configs.confirm.class" (click)="configs.confirm.onClick(this.ds.data)" [attr.data-ta]="identifiers.acceptButton">
                    {{ configs.confirm.text }}
                </button>
            </ng-container>

            <ng-container *ngIf="configs.type == DialogType.Dialog">
                <button class="btn-done" [class]="configs.done.class" (click)="configs.done.onClick()" [attr.data-ta]="identifiers.doneButton">
                    {{ configs.done.text }}
                </button>
            </ng-container>
        </div>
    </form>
</div>

<ng-template #actions>
    <h3>actions</h3>
</ng-template>
