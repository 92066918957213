import { PipeTransform, Pipe } from '@angular/core'

@Pipe({
    name: 'dynamicKeysReader',
    pure: false
})
export class DynamicKeysReaderPipe implements PipeTransform {

    transform (value: string, obj: object): any {
        let data: any = obj
        value.split('.').map( (key: string) => {
            if (data !== null) { 
                data = data[key]
            }
        })

        return data
    }
}
