import { DOCUMENT } from '@angular/common'
import { ElementRef, HostListener, Input, OnInit, Renderer2, AfterViewInit, ViewContainerRef, Directive, ComponentFactoryResolver, ApplicationRef, Injector, ComponentRef, EmbeddedViewRef, Inject, TemplateRef } from '@angular/core'
import { RgTooltipComponent } from './rg-tooltip.component'

@Directive({
    // eslint-disable-next-line
    selector: '[tooltip]'
})
export class RgTooltipDirective implements OnInit, AfterViewInit {
    @Input() tooltip: string | TemplateRef<any>

    @Input() title: string

    @Input() tooltipData: any

    @Input() hideTooltip: boolean

    @Input() tooltipType: 'success' | 'info' | 'warning' | 'danger'

    @Input() direction: 'top' | 'bottom' | 'left' | 'right'

    @Input() configs: TooltipConfigs = { }

    @Input() styles: any = { }

    compRef: RgTooltipComponent

    constructor (
        private cfr: ComponentFactoryResolver,
        private renderer: Renderer2,
        private el: ElementRef,
        private applicationRef: ApplicationRef,
        private injector: Injector
    ) {
        this.renderer.setStyle(el.nativeElement, 'position', 'relative')
    }

    ngOnInit () {
    }

    ngAfterViewInit (): void {
        this.createTooltip()
    }

    createTooltip () {
        const component = RgTooltipComponent
        // create a component reference
        const componentRef: ComponentRef<RgTooltipComponent> = this.cfr.resolveComponentFactory(component).create(this.injector);
        (componentRef.instance as any).componentRef = componentRef
        // attach component to the appRef so that so that it will be dirty checked.
        this.applicationRef.attachView(componentRef.hostView)

        // get DOM element from component
        const domElem = (componentRef.hostView as EmbeddedViewRef<any>)
            .rootNodes[0] as HTMLElement

        // this.document.body.appendChild(domElem)

        this.renderer.appendChild(this.el.nativeElement, domElem)
        this.compRef = componentRef.instance as RgTooltipComponent
        this.passInputs()
    }

    passInputs () {
        this.compRef.styles = this.styles
        this.compRef.configs = { ...this.configs }

        const defaultConfigs: TooltipConfigs = {
            title: '',
            statement: '',
            hideTooltip: false,
            type: 'warning',
            direction: 'bottom',
            template: null,
            data: { }
        }

        // Input attributes have higher priority, so overwrite config's keys if they exist
        if (typeof this.tooltip === 'string') {
            this.compRef.configs.statement = this.tooltip
            this.compRef.configs.template = defaultConfigs.template
        }
        else {
            this.compRef.configs.template = this.tooltip
            this.compRef.configs.statement = defaultConfigs.statement
        }
        this.compRef.configs.hideTooltip = this.hideTooltip ? this.hideTooltip : defaultConfigs.hideTooltip
        this.compRef.configs.title = this.title ? this.title : defaultConfigs.title
        this.compRef.configs.direction = this.direction ? this.direction : defaultConfigs.direction
        this.compRef.configs.type = this.tooltipType ? this.tooltipType : defaultConfigs.type
        this.compRef.configs.data = this.tooltipData ? this.tooltipData : defaultConfigs.data

        // create missing keys with default values
        this.compRef.configs = Object.assign(defaultConfigs, this.compRef.configs)
    }

    @HostListener('mouseenter', ['$event']) onEnter (e: MouseEvent) {
        if (this.compRef.configs.hideTooltip === false) {
            this.compRef.show = true
        }
    }

    @HostListener('mouseleave', ['$event']) onLeave (e: MouseEvent) {
        this.compRef.show = false
    }
}

export interface TooltipConfigs {
    title?: string
    statement?: string
    hideTooltip?: boolean
    type?: 'success' | 'info' | 'warning' | 'danger'
    direction?: 'top' | 'bottom' | 'left' | 'right'
    template?: TemplateRef<any> | null,
    data?: any
}
