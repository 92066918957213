import { Directive } from '@angular/core'
import { ControlValueAccessor, NgControl } from '@angular/forms'

// eslint-disable-next-line
@Directive({ selector: '[trim]' })
export class TrimInputDirective {
    constructor (private ngControl: NgControl) {
        const t: any = ngControl
        trimValueAccessor(t.valueAccessor)
    }
}

// eslint-disable-next-line
function trimValueAccessor (valueAccessor: ControlValueAccessor) {
    const original = valueAccessor.registerOnChange

    valueAccessor.registerOnChange = (fn: (_: unknown) => void) => {
        return original.call(valueAccessor, (value: unknown) => {
            return fn(typeof value === 'string' ? value.trim() : value)
        })
    }
}
