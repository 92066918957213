import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { PageNotFoundComponent } from './shared/page-not-found/page-not-found.component'

const routes: Routes = [
    {
        path: 'customer',
        loadChildren: () => import('./customer-panel/customer-panel.module')
        .then(mod => mod.CustomerPanelModule)
    },
    {
        path: 'workshop',
        loadChildren: () => import('./workshop-panel/workshop-panel.module')
        .then(mod => mod.WorkshopPanelModule)
    },
    {
        path: 'driver',
        loadChildren: () => import('./driver-panel/driver-panel.module')
        .then(mod => mod.DriverPanelModule)
    },
    {
        path: '',
        loadChildren: () => import('./website/website.module')
            .then(mod => mod.WebsiteModule)
    },
    {
        path: '**',
        component: PageNotFoundComponent,
        data: { message: 'From ROOT' }
    }
]

@NgModule({
    imports: [
        RouterModule.forRoot(routes)
    ],
    exports: [RouterModule]
})
export class AppRoutes { }
