<div class="ac-wrapper" [attr.data-ta]="taId">
    <div class="selected-items">
        <ul>
            <li class="item" [class.active]="option?.active" *ngFor="let option of selectedItems" (click)="clickItem(option)">
                <ng-container [ngTemplateOutlet]="selectedItemTemplate" [ngTemplateOutletContext]="{ option }"></ng-container>
            </li>
            <li class="input" #searchInput>
                <input type="text" [(ngModel)]="searchKeyword" (ngModelChange)="searchKeywordChange($event)" (click)="searchInpuClicked()" [disabled]="this.disabled" [attr.data-ta]="identifiers.searchInput" />
            </li>
        </ul>
    </div>

    <div class="body" *ngIf="uiBodyStatus" #body>
        <ul *ngIf="availableItems.length > 0">
            <li *ngFor="let option of availableItems | filter: searchKeyword" (click)="selectItem(option)" class="rg-ac-open-item" [attr.data-ta]="identifiers.availableItem" [attr.data-ta-value]="option.key">
                <ng-container [ngTemplateOutlet]="optionTemplate" [ngTemplateOutletContext]="{ option }"></ng-container>
            </li>
        </ul>

        <ng-container [ngTemplateOutlet]="emptyTemplate" *ngIf="availableItems.length === 0"></ng-container>
    </div>
</div>

<ng-template #optionTemplate let-option="option">
    <div class="rg-ac-default-option">
        <p [class.disabled]="option?.disabled">{{ option.value }}</p>
        <ng-container [ngTemplateOutlet]="openTooltipTemplate" [ngTemplateOutletContext]="{ option }" *ngIf="option.tooltip"></ng-container>
    </div>
</ng-template>

<ng-template #selectedItemTemplate let-option="option">
    <div class="rg-ac-default-selected-item" [attr.data-ta]="identifiers.selectedItem" [attr.data-ta-value]="option.key">
        <span class="text">{{ option.value }}</span>

        <app-rg-spinner *ngIf="option?.loading == true" class="ms-2" size="20px" border="2px"></app-rg-spinner>
        <i *ngIf="configs.singleSelect == false && option?.removable == true && option?.loading == false" class="fas fa-times" (click)="removeItem(option, $event)" [attr.data-ta]="identifiers.selectedItemDel" [attr.data-ta-value]="option.key"></i>
    </div>
</ng-template>

<ng-template #emptyTemplate>
    <div class="rg-ac-empty">No Items available</div>
</ng-template>

<ng-template #openTooltipTemplate let-option="option">
    <div class="rg-ac-default-open-tooltip-wrapper" [class]="option.tooltip.type">
        <h4 class="title" *ngIf="option.tooltip?.title">{{ option.tooltip?.title }}</h4>
        <p class="statement">{{ option.tooltip.statement }}</p>
    </div>
</ng-template>
