<!-- <div class="tooltip-grand-parent"> -->
    <div
        *ngIf="show"
        class="tooltip-wrapper-parent"
        [class]="configs.type + ' '+ configs.direction">
        <div
            class="tooltip-wrapper"
            [class.show]="show"
            [class]="configs.type + ' '+ configs.direction"
        >
            <ng-container *ngIf="configs.template == null">
                <h4 class="title" *ngIf="configs?.title">{{ configs.title }}</h4>
                <p class="statement">{{ configs.statement }}</p>
            </ng-container>

            <ng-container
                *ngIf="configs.template!=null"
                [ngTemplateOutlet]="configs.template"
                [ngTemplateOutletContext]="configs.data">
            </ng-container>
        </div>
    </div>
<!-- </div> -->
