import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { initializeApp } from 'firebase/app'
import { MessagePayload, getMessaging, getToken, onMessage } from 'firebase/messaging'
import { Subject } from 'rxjs'
import { apis, firebaseConfig } from 'src/environments/environment'
import { Resp } from '../interfaces/response'
import { ApiService } from './api.service'
import { ConstantsService } from './constants.service'

@Injectable()
export class FcmService {
    private broadcastChannel: BroadcastChannel

    public $messages: Subject<MessagePayload> = new Subject()

    baseUrl: string

    lang: any

    constructor (
        public http: HttpClient,
        public cs: ConstantsService,
        public api: ApiService
    ) {
        initializeApp(firebaseConfig)
        this.initNotificationBroadcast()
        this.requestPermission()

        this.baseUrl = apis.baseUrl + '/public'
        this.lang = this.api.translate('common')
        this.lang.subscribe((d: any) => {
            this.lang = d
        })
    }

    requestPermission () {
        const messaging = getMessaging()
        getToken(messaging, { vapidKey: firebaseConfig.vapidKey })
        .then(
            currentToken => {
                if (currentToken) {
                    this.updateUserToken(currentToken)
                    this.onForegroundMessage()
                }
                else {
                    console.log('No registration token available. Request permission to generate one.')
                }
            })
        .catch(err => {
            console.log('An error occurred while retrieving token. ', err)
        })
    }

    updateUserToken(token: string) {
        this.api.updateUserFcmToken(token).subscribe( (resp: Resp<any>) => {

        }) 
    }

    onForegroundMessage () {
        const messaging = getMessaging()
        onMessage(messaging, (payload: MessagePayload) => {
            console.log('--- FCM Received in Foreground ---', payload)
            this.$messages.next(payload)
        })
    }

    initNotificationBroadcast () {
        this.broadcastChannel = new BroadcastChannel('background-fcm-channel')
        this.broadcastChannel.addEventListener('message', event => {
            console.log('--- FCM Received in Background ---', event)
            this.$messages.next(event.data)
        })
    }
}
