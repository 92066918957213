import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core'
import { ApiService } from '../services/api.service'

@Directive({
    // eslint-disable-next-line
    selector: '[auth]'
})
export class AuthDirective {
    @Input() set auth (context: string | Array<string>) {
        this.viewContainer.clear()
        const results: boolean = this.api.checkPermissions(Array.isArray(context) ? context : [context])

        if (results) {
            this.viewContainer.createEmbeddedView(this.templateRef, context)
        }
    }

    constructor (
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private api: ApiService
    ) {

    }
}
