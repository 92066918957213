//card-title.component.ts

import { Component, Input } from '@angular/core'

@Component({
    selector: 'app-ta-identifier',
    template: `
    <div [attr.data-ta]="identifier">
    </div>
    `,
    styles: `
        div {
            width: 2px;
            height: 2px;
            color: green;
            display: block;
        }
    `
})
export class TaIdentifierComponent {
    @Input() 
    identifier: string = ''
}
