<button
    [class]="class"
    [type]="type"
    [style]="styles"
    (click)="buttonClicked($event)"
    [disabled]="disabled"
    [class.in-progress]="loader">
    <div class="loading-dots">
        <ng-content></ng-content>
        <span></span><span></span><span></span>
    </div>
</button>
