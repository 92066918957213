import { Event, Router } from '@angular/router'
import { Component, HostListener, OnInit } from '@angular/core'
import { ThemeService } from './services/theme.service'
import { TranslateService } from '@ngx-translate/core'

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
    isLoading: boolean = false
    message: any = null

    constructor(
        private route: Router,
        private themeService: ThemeService,
        public ts: TranslateService,

    ) {
        ts.addLangs(['en', 'fr'])
        const lang = localStorage.getItem('lang') ? localStorage.getItem('lang') as string : ts.getBrowserLang()
        ts.setDefaultLang(lang as string)
        ts.use(lang as string)
        this.route.events.subscribe((routerEvent: Event) => {
        })
    }

    ngOnInit() {
        const myTheme = this.themeService.getTheme()
        this.themeService.setTheme(myTheme)
    }

    @HostListener('window:blur')
    onBlur(): void {
    }

    @HostListener('window:focus')
    onFocus() {
    }

    @HostListener('window:visibilitychange')
    onVisibilityChange() {
        if (document.visibilityState === 'visible') {
            // console.log('The window is now visible')
        } else {
            // console.log('The window is now hidden')
        }
    }
}
