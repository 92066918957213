<nav class="pagination-wrapper" [class.sameline]="sameLineLabel" #paginationContainer>
    <p class="current-page" *ngIf="currentPageLabel">Current Page: {{ activePage }}</p>

    <ul class="pagination" style="display: flex; justify-content: left">
        <li class="page-item" *ngIf="activePage > 1">
            <a class="page-link btn-prev" href="javascript: void(0)" tabindex="-1" (click)="changePage(activePage - 1)" [attr.data-ta]="identifiers.previousPage">Previous</a>
        </li>
        <li class="page-item disabled" *ngIf="activePage == 1">
            <a class="page-link btn-prev" href="javascript: void(0)" tabindex="-1" [attr.data-ta]="identifiers.previousPage">Previous</a>
        </li>

        <ng-container *ngIf="pagination.last_page <= maxButtons">
            <li *ngFor="let p of [].constructor(pagination.last_page); let i = index" [class.active]="activePage == i + 1" class="page-item">
                <a class="page-link" href="javascript: void(0)" (click)="changePage(i + 1)">{{ i + 1 | number: '2.0' }}</a>
            </li>
        </ng-container>

        <ng-container *ngIf="pagination.last_page > maxButtons && maxButtons >= 3">
            <li *ngFor="let p of getPages(); let i = index" class="page-item" [class.active]="activePage == p">
                <a class="page-link" href="javascript: void(0)" (click)="changePage(p)">{{ p | number: '2.0' }}</a>
            </li>

            <li *ngIf="lastButton < pagination.last_page" [class.active]="activePage == lastButton" class="page-item">
                <a class="page-link" href="javascript: void(0)" (click)="changePage(lastButton)">...</a>
            </li>

            <li *ngIf="lastButton <= pagination.last_page" [class.active]="activePage == pagination.last_page" class="page-item">
                <a class="page-link" href="javascript: void(0)" (click)="changePage(pagination.last_page)">{{ pagination.last_page }}</a>
            </li>
        </ng-container>

        <li class="page-item" *ngIf="activePage < pagination.last_page">
            <a class="page-link btn-next" href="javascript: void(0)" tabindex="-1" (click)="changePage(+activePage + 1)" [attr.data-ta]="identifiers.nextPage">Next</a>
        </li>

        <li class="page-item disabled" *ngIf="activePage == pagination.last_page">
            <a class="page-link btn-next" href="javascript: void(0)" tabindex="-1" [attr.data-ta]="identifiers.nextPage">Next</a>
        </li>
    </ul>
</nav>
