import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RgDialogComponent } from './rg-dialog.component'
import { DialogService } from './dialog.service'
import { FormsModule } from '@angular/forms'

@NgModule({
    imports: [
        CommonModule,
        FormsModule
    ],
    declarations: [RgDialogComponent],
    providers: [ DialogService ]
})
export class RgDialogModule {
    static forRoot () {
        return {
            ngModule: RgDialogModule,
            providers: [DialogService]
        }
    }
}
