import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core'
import { Subject } from 'rxjs'
import { debounceTime, distinctUntilChanged } from 'rxjs/operators'
import { CallbackPipe } from 'src/app/pipes/callback.pipe'
import identifiers from 'src/app/services/component-identifiers'
import { TableColumnTypeEnum } from 'src/app/workshop-panel/cruds/interfaces/form-field'
import { Pagination } from '../../interfaces/response'

@Component({
    selector: 'app-rgtable',
    templateUrl: './rgtable.component.html',
    styleUrls: ['./rgtable.component.scss']
})
export class RgtableComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {

    @ViewChild('columnsList', { static: false }) refColumnsList: ElementRef

    /**
     * Input Properties
     */
    @Input() configs: RgtableConfigs

    @Input() data: Array<any> = []

    @Input() pagination: Pagination

    @Input()
    filters: any = {
        order: 'ASC',
        order_by: '',
        per_page: 25,
        keyword: '',
        page: 1
    }

    /**
     * Output Events
     */
    // eslint-disable-next-line
    @Output() public onSorting = new EventEmitter()

    @Output() public filtersChanged = new EventEmitter()

    /**
     * Local Properties
     */
    loaderOptions = {
        rows: 5,
        cols: 14,
        colSpans: [1]
    }

    spinnerSVG = '/assets/images/rolling.svg'

    searchKeyword: string = ''

    searchKeyword$: Subject<string> = new Subject<string>()

    searchKeywordSub: any

    columnList: boolean = false

    colFiltersList: Array<any> = []

    keywordSearch: boolean = false

    constructor () {
        this.searchKeywordSub = this.searchKeyword$.pipe(
            debounceTime(1000), // wait 1 sec after the last event before emitting next event
            distinctUntilChanged() // only emit if value is different from previous value
        ).subscribe((searchKeyword: string) => {
            this.filters.page = 1
            this.filters.keyword = searchKeyword
            this.filtersChanged.emit(this.filters)
        })
    }

    ngOnChanges (changes: SimpleChanges): void {
        this.setDefaults()
        this.setColumnsCount()
    }

    ngOnInit () {
        this.setDefaults()
        this.setColumnsCount()
    }

    setDefaults () {
        this.configs.pagination = this.configs.hasOwnProperty('pagination') ? this.configs.pagination : true
    }

    setColumnsCount () {
        if (this.configs?.totalColumns) {
            this.loaderOptions.cols = this.configs.totalColumns
            return
        }

        let colCount = 0
        this.configs.columns.map((col: RgTableColumn) => {
            if (col?.hide && col.hide === true) {
                return
            }
            colCount++
        })
        this.loaderOptions.cols = colCount
    }

    ngAfterViewInit () {
    }

    ngOnDestroy (): void {
        this.searchKeywordSub.unsubscribe()
    }

    doSort (orderBy: string, order: 'ASC' | 'DESC') {
        this.filters.order_by = orderBy
        this.filters.order = order
        this.filtersChanged.emit(this.filters)
    }

    setPagination (pageNo: number) {
        this.filters.page = pageNo
        this.filtersChanged.emit(this.filters)
    }

    isActiveOrder (fieldName: any, order: any) {
        return this.filters.order_by === fieldName && this.filters.order === order
    }

    searchKeywordChange (value: string) {
        this.keywordSearch = true
        this.searchKeyword$.next(value)

        if (value.toString().length == 0) {
            this.colFiltersList = []
        }
        else {
            this.matchColumns(value)
        }
    }

    matchColumns (searchString: string) {
        this.colFiltersList = []
        this.configs.columns.map( (col: any) => {
            if (col.label.toLowerCase().includes(searchString)) {
                this.colFiltersList.push({
                    value: col.value,
                    label: col.label
                })
            }
        })
    }

    @HostListener('document:click', ['$event'])
    clickEvent (event: any) {
        const element: HTMLElement = event.target as HTMLElement
        const classes = (event.target as Element).className.split(' ')

        if (!(this.refColumnsList.nativeElement as HTMLElement).contains(element)) {
            this.columnList = false
            // event.preventDefault()
            // event.stopPropagation()
        }
    }

    columnsOrderChanged (data: Array<number>) {
        const [fromIndex, toIndex] = data
        const copy: any = []

        this.configs.columns.forEach((e: any, i: number) => {
            if (i !== fromIndex) {
                copy.push(e)
                if (i === toIndex) {
                    copy.push(this.configs.columns[fromIndex])
                }
            }
        })

        if (toIndex === -1) {
            copy.unshift(this.configs.columns[fromIndex])
        }

        this.configs.columns = copy
    }

    checkType (value: any) {
        return typeof value
    }

    getIdentifier (row: any, callback: CallableFunction | undefined) {
        if (callback == undefined) {
            return ''
        }

        return new CallbackPipe().transform(row, callback)
    }

    get CallbackPipe () {
        return new CallbackPipe()
    }

    get identifiers () {
        return identifiers.rgTable
    }
}

export interface RgtableConfigs {
    styles: {
        tableHeight: string
    },
    waiting: {
        data: 'none' | 'fetching' | 'done',
        search?: 'none' | 'fetching' | 'done'
    },
    rows?: RgTableRowConfigs,
    columns: Array<RgTableColumn>
    totalColumns?: number,
    pagination?: boolean
}

export interface RgTableColumn {
    label: string
    // tslint:disable-next-line: ban-types
    value: any
    width?: string
    align?: 'center' | 'right'
    // tslint:disable-next-line: ban-types
    pipe?: any // Function | CallableFunction
    hide?: boolean
    uiType?: TableColumnTypeEnum
    multiBadgeKey?: string
    link?: string
    params?: any
    taIdentifier?: CallableFunction
}

export interface RgTableRowConfigs {
    styles?: any, // function
    class?: string,
    taIdentifier?: CallableFunction
}
