import { Directive, EventEmitter, HostBinding, HostListener, Input, Output } from '@angular/core'

@Directive({
    selector: '[appDragDrop]'
})
export class DragDropDirective {
    constructor () {
    }

    @Input('appDragDrop') position: number

    @Input() list: Array<any>

    @HostBinding('attr.draggable') draggable = true

    @Output() orderChanged = new EventEmitter<any>()

    /**
     * Dragable Target Element Events
     */
    @HostListener('dragstart', ['$event'])
    onDragStart (e: any) {
        // console.log('ondragstart', e)
        e.dataTransfer.setData('text', this.position)
    }

    /**
     * Drop Target Element Events
     */
    @HostListener('drop', ['$event'])
    onDrop (e: any) {
        // console.log('ondrop', 'from', e.dataTransfer.getData('text'), 'to', this.position)
        e.preventDefault()

        if (e.target.className.split(' ').indexOf('empty-slot') >= 0) {
            e.target.classList.remove('draging-over')
            const fromIndex = +e.dataTransfer.getData('text')
            const toIndex = this.position - 100 - 1
            // console.log('from', fromIndex, 'to', toIndex) this.position === 100 ? 0 :

            if (Math.abs(toIndex - fromIndex) === 0) {
                // console.log('no action required')
            }
            else {
                // console.log('Action Required')
                this.orderChanged.emit([fromIndex, toIndex])
            }
        }
    }

    @HostListener('dragover', ['$event'])
    onDragOver (e: any) {
        e.preventDefault()
        // console.log('e.target.className', e.target.className)
    }

    @HostListener('dragenter', ['$event'])
    onDragEnter (e: any) {
        e.preventDefault()
        // console.log('e.target.className', e.target.className)
        if (e.target.className.split(' ').indexOf('empty-slot') >= 0) {
            e.target.classList.add('draging-over')
        }
    }

    @HostListener('dragleave', ['$event'])
    onDragLeave (e: any) {
        e.preventDefault()
        if (e.target.className.split(' ').indexOf('empty-slot') >= 0) {
            e.target.classList.remove('draging-over')
        }
    }
}
