import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, Renderer2 } from '@angular/core'

@Component({
    selector: 'app-rg-alert',
    templateUrl: './rg-alert.component.html',
    styleUrls: ['./rg-alert.component.scss']
})
export class RgAlertComponent implements OnInit {
    @Input() type: 'success' | 'warning' | 'info' | 'danger'

    @Input() width: string = '400px'

    @Input() persistent: boolean = false

    @Input() buttonYes: boolean = false

    @Input() buttonNo: boolean = false

    @Input() buttonClose: boolean = false

    // eslint-disable-next-line
    @Output() public onYes = new EventEmitter()

    // eslint-disable-next-line
    @Output() public onNo = new EventEmitter()

    content = ''

    @Input() colors: any = {
        success: {
            normal: '#e3ffd6',
            dark: '#3ab25a'
        },
        warning: {
            normal: '#fef7ea',
            dark: '#e28c05'
        },
        info: {
            normal: '#e6eff8',
            dark: '#0967d1'
        },
        danger: {
            normal: '#fcede9',
            dark: '#e94d2a'
        }
    }

    hide: boolean = true

    constructor (private elementRef: ElementRef, private r2: Renderer2) {

    }

    ngOnInit () {
        this.elementRef.nativeElement.style.setProperty('--normal', this.colors[this.type].normal)
        this.elementRef.nativeElement.style.setProperty('--dark', this.colors[this.type].dark)
        this.elementRef.nativeElement.style.setProperty('--width', this.width)
        setTimeout( () => this.hide = false, 0)
    }

    @HostListener('transitionend', ['$event'])
    transitionend (e: any) {
        if (e.target.className.split(' ').indexOf('hide') >= 0) {
            e.target.classList.add('remove')
        }
    }

    yesClicked (e: any) {
        this.onYes.emit(e)
    }

    noClicked (e: any) {
        this.onNo.emit(e)
    }
}
