import { Component, OnInit, Input, ElementRef } from '@angular/core'

@Component({
    selector: 'app-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.css']
})
export class LoaderComponent implements OnInit {
    @Input() status = true

    @Input() height: number

    @Input() backgroundColor: string

    constructor (private elementRef: ElementRef) {
    }

    ngOnInit () {
        if (!this.height) {
            this.height = 30
        }

        if (!this.backgroundColor) {
            this.backgroundColor = 'rgb(240, 240, 240, 0.3)'
        }

        this.elementRef.nativeElement.style.setProperty('--height', `${this.height}px`)
        this.elementRef.nativeElement.style.setProperty('--backgroundColor', `${this.backgroundColor}px`)
    }

}
