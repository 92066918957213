import { Input, AfterViewInit, ElementRef, Renderer2, OnChanges, SimpleChanges, Directive } from '@angular/core'

@Directive({
    // eslint-disable-next-line
    selector: '[collapse]'
})
export class CollapsibleDirective implements AfterViewInit, OnChanges {
    @Input() collapse: boolean

    @Input() minHeight: number = 0

    @Input() padding: number = 0

    @Input() speed: number = 1

    @Input() items: any

    changes: SimpleChanges

    constructor (private renderer: Renderer2, private el: ElementRef) {
        this.renderer.setStyle(el.nativeElement, 'transition', `all ${this.speed}s`)
        this.renderer.setStyle(el.nativeElement, 'overflow', 'hidden')
    }

    ngAfterViewInit () {
        this.calculateHeight(0)
        /*
        const container = this.el.nativeElement
        const observer = new ResizeObserver( () => {
            console.log('New scrollHeight', container.scrollHeight)
            this.preferedHeight = container.scrollHeight
        })

        for (var i = 0; i < container.children.length; i++) {
            observer.observe(container.children[i])
        }
        */
    }

    ngOnChanges (changes: SimpleChanges) {
        this.changes = changes
        this.calculateHeight(20)
    }

    calculateHeight (addition: number) {
        const e = this.el.nativeElement
        if ( this.collapse == true) {
            // this.renderer.addClass(this.el.nativeElement, 'collapsed')
            this.renderer.setStyle(e, 'height', '0')
            this.renderer.setStyle(e, 'min-height', `${this.minHeight}px`)
            this.renderer.setStyle(e, 'padding-top', `${this.padding}px`)
            this.renderer.setStyle(e, 'padding-bottom', `${this.padding}px`)
        }
        else {

            this.renderer.removeStyle(e, 'min-height')
            this.renderer.removeStyle(e, 'padding-top')
            this.renderer.removeStyle(e, 'padding-bottom')

            setTimeout( () => {
                let height = (e.scrollHeight + addition) + 'px'
                if (this.changes.collapse == undefined) {
                    height = 'auto'
                }

                this.renderer.setStyle(e, 'height', height)
            }, 0)
        }
    }
}
