import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgApexchartsModule } from 'ng-apexcharts';
import { GeneralChartComponent } from './general-chart/general-chart.component';
import { DataService } from './general-chart/general-chart.service';



@NgModule({
    imports: [
        CommonModule,
        NgApexchartsModule
    ],
    declarations: [
    GeneralChartComponent,
  ],
    exports: [GeneralChartComponent],
    providers: [DataService]
})
export class GeneralChartModule { }
