<input type="file" #fileInputRef class="d-none" (change)="onFilesChange($event)" />

<div #uploaderWrapper class="uploader-wrapper" (dragleave)="onDragLeave($event)" (dragover)="onDragOver($event)" (drop)="onDrop($event)" (click)="triggerClickOnFile()">
    <div class="instructions">
        @if (!filesList) {
            <!-- <i class="upload-file fa-solid fa-file-arrow-up"></i> -->
            <i class="upload-file fa-solid fa-arrow-up-from-bracket"></i>
            <p class="msg">{{ lang.upload_message }}</p>
        } @else {
            @if (this.filesList.item(0)?.name) {
                <img class="known-file" [src]="'/assets/images/' + getFileType(this.filesList.item(0)) + '.png?extra=' + this.filesList.item(0)?.name" />
                <p class="filename">{{ this.filesList.item(0)?.name }}</p>

                @if (getFileType(this.filesList.item(0)) == 'unknown') {
                    <p class="error-msg">{{ lang.invalid_file_type }}</p>
                }
            } @else {
                <i class="upload-file fa-solid fa-arrow-up-from-bracket"></i>
                <p class="msg">{{ lang.upload_message }}</p>
            }
        }

        @if (this.validationResult === true) {
            <div class="progress-bar">
                <div class="progress-track"></div>
                <div class="completed" [style.width]="this.uploadProgress + '%'"></div>
                <p class="percentage">{{ this.uploadProgress }}%</p>
            </div>
        }
    </div>
</div>
