import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http'
import { APP_INITIALIZER, NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import { AppComponent } from './app.component'
import { AppRoutes } from './app.routing'
import './extensions/array'
import { UIHelpers } from './helpers/ui-helpers'
import { ApiInterceptorsService } from './interceptors/api.interceptor'
import { CacheInterceptor } from './interceptors/cache.interceptor'
import { HttpErrorsInterceptorService } from './interceptors/http-errors.interceptor'
import { ConstantsService } from './services/constants.service'
import { FcmService } from './services/fcm.service'
import { OverridesService } from './services/overrides.service'
import { SharedModule } from './shared/shared.module'
import { LayoutModule } from './website/layout/layout.module'
// AOT compilation support
export const httpTranslateLoader = (http: HttpClient) => {
    return new TranslateHttpLoader(http)
}

export const constantsServiceFactoryProvider = (translate: TranslateService) => {
    switch (localStorage.getItem('lang')) {
    case 'en': {
        return new ConstantsService()
    }
    case 'fr': {
        return new ConstantsService()
    }
    default: {
        return new ConstantsService()
    }
    }
}

export const appOverridesFactory = (os: OverridesService) => {
    return (): any => {
        return os.init()
    }
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutes,
        LayoutModule,
        SharedModule,
        HttpClientModule,
        TranslateModule.forRoot({
            defaultLanguage: 'en',
            loader: {
                provide: TranslateLoader,
                useFactory: httpTranslateLoader,
                deps: [HttpClient]
            }
        })
    ],
    providers: [
        ConstantsService,
        FcmService,
        OverridesService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ApiInterceptorsService,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: CacheInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpErrorsInterceptorService,
            multi: true
        },
        {
            provide: APP_INITIALIZER,
            useFactory: appOverridesFactory,
            deps: [OverridesService],
            multi: true
        },
        UIHelpers
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
