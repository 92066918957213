<ng-container *ngFor="let e of control.errors | keyvalue">
    <div *ngIf="ui.checkErrors(control, f)" class="errors" [attr.data-ta-of]="taId">
        <p *ngIf="e.key === 'required'" data-ta-error="isRequiredError">{{ label }} {{ lang.isRequired }}</p>

        <p *ngIf="e.key === 'email'" data-ta-error="validEmailError">{{ label }} {{ lang.invalidEmail }}</p>

        <p *ngIf="e.key === 'minlength'" data-ta-error="minLengthError">{{ label }} {{ lang.minLength }} {{ control?.errors?.minlength?.requiredLength }} {{ lang.characters }}</p>
        <p *ngIf="e.key === 'maxlength'" data-ta-error="maxLengthError">{{ label }} {{ lang.maxLength }} {{ control?.errors?.maxlength?.requiredLength }} {{ lang.characters }}</p>

        <p *ngIf="e.key === 'min'" data-ta-error="minError">{{ label }} {{ lang.min }} {{ control?.errors?.min?.min }}</p>
        <p *ngIf="e.key === 'max'" data-ta-error="maxError">{{ label }} {{ lang.max }} {{ control?.errors?.max?.max }}</p>
    </div>
</ng-container>
