export enum WorkOrderLogTypeEnum {
    WoPickup = 'WoPickup',
    WoDiagnose = 'WoDiagnose',
    WoRepair = 'WoRepair',
    WoClaim = 'WoClaim',
    WoNowClaim = 'WoNowClaim',
    WoInstruction = 'WoInstruction',
    WoUnfix = 'WoUnfix',
    WoOnHold = 'WoOnHold',
    WoQualityControl = 'WoQualityControl',
    WoNonRepair = 'WoNonRepair'
}
